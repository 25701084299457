import { Radio as RadioBtn, withStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: 0,
      '& span': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& span:first-child': {
        ...theme.leads.lead2 ,
        letterSpacing: '0px',
        lineHeight: '2.8rem',
      },
      '@media (max-width: 600px)': {
        '& span:first-child': {
          ...theme.leads.lead2,
        },
        '& span': {
          ...theme.bodyStyles.body2,
          paddingTop: '0.15rem',
        },
      },
    },
    inputLabel: {
      marginBottom: '0.4rem',
    },
    selectedRadioBtnMainText: {
      fontWeight: 300,
      color: theme.palette.dark.main,
    },
    selectedRadioBtnSecondaryText: {
      margin: 0,
    },
    radioIcon: {
      // color: theme.palette.primary.main,
    },
  }),
  { index: 1 },
);

export const Radio = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(RadioBtn);
