import React from 'react';
import { useStyles } from './yourDetailsFinalReviewStyles';
import { Typography } from '../../atoms';
import { Box, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';

interface IYourDetailsFinalReview {
    firstNameValue: string;
    lastNameValue: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    townValue: string;
    postCodeValue: string;
    contactNumber: string;
    emailId: string;
}

const YourDetailsFinalReview: React.FC<IYourDetailsFinalReview> = ({
    firstNameValue,
    lastNameValue,
    addressLine1,
    addressLine2,
    addressLine3,
    townValue,
    postCodeValue,
    contactNumber,
    emailId
}: IYourDetailsFinalReview): JSX.Element => {
    const classes = useStyles();
    return (
        <>
            <div className="pl1">
                <Grid xs={12}>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="h3">Your details</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>First name</Typography>
                        <Typography variant="body3">{firstNameValue}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Last name</Typography>
                        <Typography variant="body3">{lastNameValue}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Address line 1</Typography>
                        <Typography variant="body3">{addressLine1}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Address line 2</Typography>
                        <Typography variant="body3">{addressLine2}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Address line 3</Typography>
                        <Typography variant="body3">{addressLine3}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Town</Typography>
                        <Typography variant="body3">{townValue}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Postcode</Typography>
                        <Typography variant="body3">{postCodeValue}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Contact number</Typography>
                        <Typography variant="body3">{contactNumber}</Typography>
                    </Box>
                    <Box className={clsx(classes.customerInfoContent, "py05")}>
                        <Typography variant="body1" className={classes.displayAddressValue}>Email</Typography>
                        <Typography variant="body3">{emailId}</Typography>
                    </Box>
                </Grid>
            </div>
            <Divider className={clsx(classes.summaryDivider, classes.bgBlue)}></Divider>
        </>
    );
};

export default YourDetailsFinalReview;
