import React from 'react';
import { useStyles } from './vehicleDetailsFinalReviewStyles';
import { Typography } from '../../atoms';
import { Box, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';

interface IVehicleDetails {
    vehicleMake: string;
    model: string;
    registration: any;
    year: number;
}

const VehicleDetailsFinalReview: React.FC<IVehicleDetails> = ({
    vehicleMake,
    model, 
    registration,
    year,
}: IVehicleDetails): JSX.Element => {
    const classes = useStyles();
    return (
        <div>
            <Grid container>
                <div className="pl2">
                    <Grid xs={12}>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Make</Typography>
                            <Typography variant="body3">{vehicleMake}</Typography>
                        </Box>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Model</Typography>
                            <Typography variant="body3">{model}</Typography>
                        </Box>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Registration</Typography>
                            <Typography variant="body3">{registration}</Typography>
                        </Box>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Year</Typography>
                            <Typography variant="body3">{year}</Typography>
                        </Box>
                    </Grid>
                </div>
                <Divider className={clsx(classes.summaryDivider, classes.bgBlue)}></Divider>
            </Grid>
        </div>
    );
};

export default VehicleDetailsFinalReview;
