import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        ...theme.layouts.stepGrid,
        backButton: {
            '@media (max-width: 767px)': {
                display: 'flex',
                alignItems: 'center',
            },
            '@media (min-width: 768px)': {
                padding: '1rem',
            },
        },
    }),
    { index: 1 },
);
