import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography } from '..';
import { useStyles } from './YourDetailsStyles';
import { convertDateToDisplayFormat } from '../../../utils/dateFormattingUtils';
import clsx from 'clsx';

interface IYourDetails {
  firstName: string;
  lastName: string;
  dateOfBirth: Date | string | null;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  addressLine5: string;
  postcode: string;
  partnerInitial: string;
  partnerSurname: string;
}

export const YourDetails: React.FC<IYourDetails> = ({
  firstName,
  lastName,
  dateOfBirth,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  addressLine5,
  postcode,
  partnerInitial,
  partnerSurname,
}: IYourDetails) => {
  const classes = useStyles();

  const partnerDetailPopulated:boolean = partnerSurname != undefined && partnerSurname != null && partnerSurname != '';
  const displayDob:string = dateOfBirth ? convertDateToDisplayFormat(dateOfBirth.toString()) : '';

  return (
    <>
      <Typography className={classes.yourDetailsHeading} variant="h3">
        Your details
      </Typography>
      <div className={classes.yourDetailsContainer}>
        <Grid container>
          <Grid item xs={6} sm={4} md={3} lg={2} className="pt05">
            <Typography className={classes.field}>Name:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={clsx(classes.fieldValue, classes.overflowHidden)}>
              {firstName} {lastName}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} className="pt05">
            <Typography className={classes.field}>Date of birth:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={classes.fieldValue}>{displayDob}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} className="pt05">
            <Typography className={classes.field}>Address:</Typography>
          </Grid>
          <Grid item xs={6} sm={8} md={9} lg={10} className="pt05">
            <Typography className={classes.fieldValue}>{addressLine1}{addressLine2 !== '' ? ` ${addressLine2} ` : ''}</Typography>
            <Typography className={classes.address}>
              {addressLine3 !== '' ? `${addressLine3}, ` : ''}{' '}
              {addressLine4 !== '' ? `${addressLine4}, ` : ''}
              {addressLine5 !== '' ? `${addressLine5}, ` : ''} {postcode}
            </Typography>
          </Grid>
          {partnerDetailPopulated ? <>
            <Grid item xs={6} sm={4} md={3} lg={2} className="pt2">
                <Typography className={classes.field}>Partner&apos;s name:</Typography>
              </Grid>
              <Grid item xs={6} sm={8} md={9} lg={10} className="pt2">
                <Typography className={classes.fieldValue}>
                  {`${partnerInitial?`${partnerInitial}.`:''} ${partnerSurname}`}
                </Typography>
              </Grid>
            </> : <></>
          }
        </Grid>
      </div>
    </>
  );
};

export default YourDetails;
