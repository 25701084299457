import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Theme,
} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  accordionHeading: {
    margin: '0.5rem 0rem 1rem 0rem',
    color: theme.palette.common.black,
  },
  accordionBody: {
    backgroundColor: theme.palette.common.white,
    fontSize: '1.3rem',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginBottom: '0.75rem',
    },
    '& p:last-child': {
      marginBottom: '1rem',
    },
    '& a': {
      color: theme.palette.dark[800],
    },
  },
  expandMoreIcon: {
    fontSize: '1.75rem',
  },
  accordionSummary: {
    '& .Mui-expanded': {
      '& svg': {
        color: theme.palette.dark[900],
      }
    },
  },
}));

export const CSSAccordion = withStyles({
  root: {
    padding: '0px',
    backgroundColor: 'rgba(44, 148, 167, 0.1',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.black,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    padding: '0px 1rem 1rem 1.5rem',
  },
}))(MuiAccordionDetails);
