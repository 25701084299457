import React from 'react';
import { useStyles } from './AboutYourCoverStyles';
import { Typography } from '../../atoms';
import { Box, Divider } from '@material-ui/core';
import clsx from 'clsx';
interface IAboutYourCover {
    coverLevel: string;
    coverLevelPrice?: string;
    hasSecondVehicle?: boolean;
    secondVehiclePrice?: string;
    hasPersonalCover?: boolean;
    personalCoverPrice?: string;
    totalCostValue: number;
}

const AboutYourCover: React.FC<IAboutYourCover> = ({
    coverLevel,
    coverLevelPrice,
    hasSecondVehicle,
    secondVehiclePrice,
    hasPersonalCover,
    personalCoverPrice,
    totalCostValue
}: IAboutYourCover): JSX.Element => {
    const classes = useStyles();
    return (
        <div>
            <Box className={clsx(classes.yourCoverLabel, classes.bgLightBlue)}>
                <Typography variant="h3">About your cover</Typography>
            </Box>
            {hasSecondVehicle === true ? (
                <Box className={classes.coverContent}>
                    <Typography variant="h3">Vehicle one</Typography>
                </Box>
            ) : <></>}
            <Box className={classes.coverContent}>
                <Typography variant="h3">Cover level</Typography>
            </Box>
            <Box className={classes.coverContent}>
                <Typography variant="h5">{coverLevel}</Typography>
                <Typography variant="h5">&#163;{coverLevelPrice ? coverLevelPrice : "0"}</Typography>
            </Box>
            <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
            <Box className={classes.coverContent}>
                <Typography variant="h4">Additional extras</Typography>
            </Box>
            {(hasPersonalCover === false) ? (
                <>
                    <Box className={classes.coverContent}>
                        <Typography variant="h5">None</Typography>
                        <Typography variant="h5">&#163;{0}</Typography>
                    </Box>
                </>
            ) :
                <>
                    <Box className={classes.coverContent}>
                        <Typography variant="h5">Personal Cover</Typography>
                        <Typography variant="h5">&#163;{personalCoverPrice}</Typography>
                    </Box>
                </>
            }

            {hasSecondVehicle === true && (
                <>
                    <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
                    <Box className={classes.coverContent}>
                        <Typography variant="h3">Vehicle two</Typography>
                    </Box>
                    <Box className={classes.coverContent}>
                        <Typography variant="h4">Cover level</Typography>
                    </Box>
                    <Box className={classes.coverContent}>
                        <Typography variant="h5">{coverLevel}</Typography>
                        <Typography variant="h5">&#163;{secondVehiclePrice}</Typography>
                    </Box>
                    <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
                    <Box className={classes.coverContent}>
                        <Typography variant="h4">Additional extras</Typography>
                    </Box>
                    <Box className={classes.coverContent}>
                        <Typography variant="h5">None</Typography>
                        <Typography variant="h5">&#163;{0}</Typography>
                    </Box>
                </>
            )}

            <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
            <Box className={classes.coverContent}>
                <Typography variant="h3">Total cost</Typography>
                <Typography variant="h3">&#163;{totalCostValue.toFixed(2)}</Typography>
            </Box>
            <Box className={classes.coverContent}>
                <Typography variant="h5">Includes Insurance Premium Tax where applicable.</Typography>
            </Box>
            <Divider className={clsx(classes.endDivider, classes.bgBlue)}></Divider>
        </div>
    );
};

export default AboutYourCover;
