import React, { useContext, useEffect } from 'react';
import {
    StepChecker,
    DocumentTitle,
    Typography,
    ButtonSecondary,
    Divider,
    ButtonPrimary,
    YourDetailsFinalReview,
    YourCoverFinalReview,
    VehicleDetailsFinalReview,
    AboutYourCover,
} from '../../components/atoms';
import { Grid, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './LetsReviewStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { getPersonalCoverPrice, getSecondVehiclePrice } from '../../utils/coverDetailsUtils';

export const LetsReview: React.FC = () => {
    const { activeStep, updateActiveStep, data, updateData, updateShowHero, updateShowStepper } = useContext(StepContext);
    const history = useHistory();
    const classes = useStyles();

    const vehicleYear = (new Date().getFullYear() - data.vehicleAge);
    const additionalVehicleYear = (new Date().getFullYear() - data.additionalVehicleAge);

    useEffect(() => {
        updateActiveStep(4);
        updateShowHero(false);
        updateShowStepper(true);
    }, []);
    
    const {
        handleSubmit,
        } = useForm({
            mode: 'onSubmit',
            reValidateMode: 'onChange',
            defaultValues: {
                ...data,
                showLookup: true,
            },
            shouldFocusError: true,
            shouldUnregister: true,
    });

    const onSubmit = (stepData: IStepData) => {
        updateActiveStep(activeStep + 1);
        updateData({
        ...data,
        ...stepData,
        });
        history.push(steps[Step.PAYMENT].url);
    };


    const handleBack = async () => {
        updateActiveStep(activeStep - 1);
        updateData({
        ...data,
        });
        history.push(steps[Step.YOUR_DETAILS].url);
    };

    return (
        <PageTemplate>
            <StepChecker />
            <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Final Review`} />
            <Grid container className={classes.stepper}>
                <Box>
                    <Grid item xs={12} lg={12} className="pb1">
                        <Typography variant="h2">
                            Let&apos;s review.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.responsiveHeading}>
                        <Typography variant="body1">
                            Here&apos;s a summary of all the information you&apos;ve given us. Please make sure everything&apos;s correct, then confirm your payment.
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
            <div className={classes.responsiveXPadding}>
                <Grid container className={classes.gridMainContainer}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1">
                            Check that all your information is correct, as it will form part of the insurance policy between us. Incorrect information may invalidate some or all of 
                            the policy. Insurance cover is subject to our standard policy wording. 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8} className="pt2">
                        <Typography variant="body3">
                            If your circumstances change between when you purchase your policy and the date it&apos;s meant to start, please call 0800 400 654 and let us know.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            
            <Box className="pt2">
                <YourCoverFinalReview
                    coverStartDate={data.policyStartDate}
                    coverEndDate={data.policyEndDate}
                />
            </Box>

            {data.partnerInitial !== '' && data.partnerSurname !== '' && (
                <>
                    <Grid className={clsx(classes.gridMainContainer, "pl1")}>
                        <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
                    </Grid>
                    <Grid container className={classes.gridMainContainer}>
                        <Grid xs={12} className="pl1">
                            <Box className="py05">
                                <Typography variant="h3">
                                    Partner details
                                </Typography>
                            </Box>
                            <Box className={clsx(classes.customerInfoContent, "py05")}>
                                <Typography variant="body1" className={classes.displayAddressValue}>Partner Initial</Typography>
                                <Typography variant="body3">{data.partnerInitial}</Typography>
                            </Box>
                            <Box className={clsx(classes.customerInfoContent, "py05")}>
                                <Typography variant="body1" className={classes.displayAddressValue}>Partner Surname</Typography>
                                <Typography variant="body3">{data.partnerSurname}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid className={clsx(classes.gridMainContainer, "pl1")}>
                <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
            </Grid>
            <Grid container className={classes.gridMainContainer}>
                <YourDetailsFinalReview
                    firstNameValue={data.firstName}
                    lastNameValue={data.lastName}
                    addressLine1={data.addressLine1}
                    addressLine2={data.addressLine2}
                    addressLine3={data.addressLine3}
                    townValue={data.addressLine4 ? data.addressLine4 : data.addressLine3}
                    postCodeValue={data.postcode}
                    contactNumber={data.phoneNumber.toString()}
                    emailId={data.emailAddress}
                >
                </YourDetailsFinalReview>
            </Grid>
            <Grid className={clsx(classes.gridMainContainer, "pl1")}>
                <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
            </Grid>

            <Grid container className={classes.gridMainContainer}>
                <Grid item xs={12}>
                    <Typography className="pl2" variant="h3"> Vehicle {data.additionalVehicleRegistrationNumber !== '' ? "one" : ""} details</Typography>
                </Grid>
                <Grid item xs={12}>
                    <VehicleDetailsFinalReview 
                        vehicleMake={data.vehicleMake}
                        model={data.vehicleModel}
                        registration={data.registrationNumber}
                        year={vehicleYear}
                    />
                </Grid>
            </Grid>

            {data.additionalVehicleRegistrationNumber !== '' && (
                <>
                    <Grid container className={classes.gridMainContainer}>
                        <Grid item xs={12}>
                            <Typography className="pl2" variant="h3"> Vehicle two details</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <VehicleDetailsFinalReview 
                                vehicleMake={data.additionalVehicleMake}
                                model={data.additionalVehicleModel}
                                registration={data.additionalVehicleRegistrationNumber}
                                year={additionalVehicleYear}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid className={clsx(classes.gridMainContainer, "pl1")}>
                <Divider className={clsx(classes.divider, classes.bgBlue)}></Divider>
            </Grid>
            <div className="pt3">
                <Grid className={classes.gridMainContainer}>
                    <AboutYourCover
                        coverLevel={data.baseOption}
                        coverLevelPrice={data.priceData.find(item => item.option === data.baseOption)?.price}
                        hasSecondVehicle={data.additionalOptions.includes("AdditionalVehicle")}
                        secondVehiclePrice={getSecondVehiclePrice(data.options)}
                        hasPersonalCover={data.personalCover.includes('True') ? true : false}
                        personalCoverPrice={getPersonalCoverPrice(data.options)}
                        totalCostValue={data.coverPrice? data.coverPrice : 0}
                    >
                    </AboutYourCover>
                </Grid>
            </div>
            <div className="mb2">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container className={clsx(classes.gridMainContainer, "mb2")}>
                        <Grid item xs={12} sm={6} className={classes.backButton}>
                            <Box>
                                <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.actionButton}>
                                <ButtonPrimary type="submit">
                                    Continue to payment
                                </ButtonPrimary>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </PageTemplate>
    );
};

export default LetsReview;
