const ADDITIONAL_VEHICLE = 'AdditionalVehicle';
const PERSONAL = 'Personal';


export const buildAdditionalOptions = (additionalVehicle: string, personalCover: string): string[] => {

  const hasPersonalCover = personalCover?.toLowerCase() === 'true' || personalCover?.toLowerCase() === 'yes';
  const hasAdditionalVehicle = additionalVehicle?.toLowerCase() === 'true' || additionalVehicle?.toLowerCase() === 'yes' || additionalVehicle?.toLowerCase() === 'dual';
  const additionalOptions: Array<string> = [];

  if (hasPersonalCover) {
    additionalOptions.push(PERSONAL);
  }

  if (hasAdditionalVehicle) {
    additionalOptions.push(ADDITIONAL_VEHICLE);
  }

  return additionalOptions;
};
