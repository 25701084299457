import { addDays } from 'date-fns';
import * as yup from 'yup';

const maximumStartDate = addDays((new Date()), 90);

export const styleGuideYupSchema = yup.object().shape({
  radioBtnTest: yup.string().required('Radio Button is required.'),
  textFieldTest: yup.string().required('TextField is required.'),
  textFieldMinMaxTest: yup
    .number()
    .min(1, 'Must be a number between 1 and 10.')
    .max(10, 'Must be a number between 1 and 10.'),
  checkboxTest: yup.bool().oneOf([true], 'Checkbox must be checked.'),
  dropdownTest: yup.string().required('Dropdown is required.'),
  datePickerTest: yup
  .date()
  .required('Please select your cover start date.')
  .min(new Date(), 'You cannot choose a date before today.')
  .max(new Date(maximumStartDate), 'Please choose a date between now and 90 days.')
  .nullable(),
  vehicleLookUp: yup.object().shape({
    registrationNumber: yup
    .string()
    //.matches(/^[A-Za-z]{2}[0-9]{2}[A-Za-z]{3}$/, 'Please enter a valid registration number')
    .required('Let us know your registration number'),
  }),
  postcodeLookup: yup.object().shape({
    firstLineOfAddress: yup.string().required('First Line of Address is required.'),
    secondLineOfAddress: yup.string(),
    town: yup.string().required('Town is required.'),
    county: yup.string(),
    postcode: yup.string().required('Postcode is required.'),
  }),
});

export default styleGuideYupSchema;
