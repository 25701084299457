import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    tableHead: {
        borderBottom: `2px solid ${theme.palette.primary.dark}`,
    },
    tableBody: {
        '&>*:nth-of-type(even)': {
            backgroundColor: theme.palette.primary.contrastText,
        },
    },

    tabLines: {
        backgroundColor: theme.palette.primary.contrastText,
        cursor: "pointer",
    },

    specificButton: {
        backgroundColor: theme.palette.common.white + ' !important',
    },

    tableHeaderItems: {
        "&$tableCellSelected, &$tableRowSelected:hover": {
            border: '5px solid',
            borderRadius: '0px'
        },
        "&$tableCell": {
            border: '2px solid red'
        },
        border: `2px solid ${theme.palette.primary.dark}`,
        borderRadius: '5% 5% 0 0'
    },


    checkIcon: {
        color: theme.palette.primary.dark,
    },
}));
