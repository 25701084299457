import React, { useContext, useEffect } from 'react';
import { useStyles } from './AllSortedStyles';
import { ButtonPrimary, Divider, DocumentTitle, LinkPrimary, Typography } from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { StepContext } from '../../contexts/StepContext';
import { Grid, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

export const AllSorted: React.FC = () => {
  const { activeStep, data, updateShowStepper, updateShowHero } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  if (process.env.NODE_ENV === 'development' ? false : activeStep !== 5 || data.paymentSuccessful !== true) {
    history.push('/');
    return <></>;
  }

  const getDocument = (filePath: string) => window.open(filePath);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateShowStepper(false);
    updateShowHero(false);
  }, []);

  useEffect(() => {
    // Trigger GTM DoubleClick event
      window.gtag('event', 'purchase', {
        allow_custom_scripts: true,
        value: data.coverPrice,
        transaction_id: data.quote,
        send_to: 'DC-3535201/green028/green001+transactions',
      });
  }, []);

  return (
    <>
      <PageTemplate>
        <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - All Sorted`} />
        <Grid container className={classes.stepper}>
          <Box className={classes.allSortedHeader}>
            <Grid item xs={12} lg={8} className="pb1">
              <Typography variant="h2">
                Thank you for getting MAYDAY breakdown cover.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LinkPrimary onClick={() => getDocument('/pdfs/MAYDAY-Policy-booklet.pdf')}>
                Policy booklet
              </LinkPrimary>
            </Grid>
          </Box>
        </Grid>
        <Grid container className={classes.allSortedDisplay}>
          <Grid item xs={12} lg={8} className="pb1">
            <Box className={classes.allSortedBody}>
              <Typography className="my1">
                After we&apos;ve processed your details, we&apos;ll be in touch via email to confirm that you&apos;re covered.
              </Typography>
              <Typography className="my1">
                You&apos;ll then receive all your important policy documents shortly after.
              </Typography>
              <Typography className="my1">
                If you have any questions about your cover in the meantime, please don&apos;t hesitate to get in touch.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className="py1">
              <ButtonPrimary buttonType="light" href="https://www.greenflag.com">
                Home
              </ButtonPrimary>
            </Box>
            <Divider className={clsx(classes.divider, classes.bgBlue, "my2")}></Divider>
          </Grid>
        </Grid>
      </PageTemplate>
    </>
  );
};

export default AllSorted;
