import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const GlobalCss = withStyles((theme: Theme) => ({
  '@global': {
    /* Helper */
    '.fw-500': {
      fontWeight: 500,
    },
    '.textCenter': {
      textAlign: 'center',
    },
    '.minWidth100': {
      minWidth: '100%',
    },

    /* Margin */
    '.ml1': {
      marginLeft: '1rem',
    },
    '.mr1': {
      marginRight: '1rem',
    },
    '.mt2': {
      marginTop: '2rem',
    },
    '.mt3': {
      marginTop: '3rem',
    },
    '.mt4': {
      marginTop: '4rem',
    },
    '.mb05': {
      marginBottom: '0.5rem',
    },
    '.mb1': {
      marginBottom: '1rem',
    },
    '.mb2': {
      marginBottom: '2rem',
    },
    '.mb3': {
      marginBottom: '3rem',
    },
    '.my1': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    '.my2': {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
    '.my3': {
      marginTop: '3rem',
      marginBottom: '3rem',
    },
    '.mx1': {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '.m1': {
      margin: '1rem',
    },

    /* Padding */
    '.pl1': {
      paddingLeft: '1rem',
    },
    '.pl2': {
      paddingLeft: '2rem',
    },
    '.pl3': {
      paddingLeft: '3rem',
    },
    '.pr1': {
      paddingRight: '1rem',
    },
    '.pr2': {
      paddingRight: '2rem',
    },
    '.pr3': {
      paddingRight: '3rem',
    },
    '.pt1': {
      paddingTop: '1rem',
    },
    '.pt2': {
      paddingTop: '2rem',
    },
    '.pt3': {
      paddingTop: '3rem',
    },
    '.pb1': {
      paddingBottom: '1rem',
    },
    '.pb2': {
      paddingBottom: '2rem',
    },
    '.pb3': {
      paddingBottom: '3rem'
    },
    '.py05': {
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
    '.py1': {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    '.py2': {
      paddingTop: '2rem',
      paddingBottom: '2rem',
    },
    '.px1': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    '.px2': {
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    '.px3': {
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    '.p1': {
      padding: '1rem',
    },

    '.lead1': theme.leads.lead1,
    '.lead2': theme.leads.lead2,
    '.overline1': theme.overlines.overline1,
    '.overline2': theme.overlines.overline2,
    '.fs-elliot-bold': {
      fontFamily: 'fs-elliot-pro-bold',
    },
    '.fs-elliot-pro': {
      fontFamily: 'fs-elliot-pro-regular',
    },

    '.inputLabel': {
      ...theme.form.labels.inputField.label,
    },
    '.fieldError': {
      ...theme.form.labels.selectFieldSmall.hint,
      color: theme.palette.utility.redDark,
      fontWeight: 500,
      lineHeight: '2.25rem',
      margin: '0rem',
    },
    '.fieldErrorLong': {
      ...theme.form.labels.selectFieldSmall.hint,
      color: theme.palette.utility.redDark,
      fontWeight: 500,
      lineHeight: '1.5rem',
    },
    '.anchorLink': {
      color: theme.palette.common.black,
      fontFamily: 'fs-elliot-pro-bold',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '18px',
      lineHeight: '22px',
    },

    '.smallAnchorLink': {
      color: theme.palette.common.black,
      fontFamily: 'fs-elliot-pro-bold',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '1rem',
      lineHeight: '1.3rem',
    },
  },
}))(() => null);

export default GlobalCss;
