import React from 'react';
import { ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonSecondaryTableBottomStyles';
import ButtonSecondaryLight from './ButtonSecondaryLight/ButtonSecondaryLight.component';
import ButtonSecondaryDark from './ButtonSecondaryDark/ButtonSecondaryDark.component';

interface IButtonSecondaryTableBottomProps extends ButtonProps {
  buttonType?: 'dark' | 'light';
  loading?: boolean;
}

export const ButtonSecondaryTableBottom: React.FC<IButtonSecondaryTableBottomProps> = ({
  buttonType,
  disabled,
  className,
  loading,
  children,
  ...rest
}: IButtonSecondaryTableBottomProps): JSX.Element => {
  const classes = useStyles();
  switch (buttonType) {
    case 'light':
      return (
        <ButtonSecondaryLight
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonSecondaryLight>
      );
    case 'dark':
      return (
        <ButtonSecondaryDark
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonSecondaryDark>
      );
    default:
      return (
        <ButtonSecondaryLight
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonSecondaryLight>
      );
  }
};

export default ButtonSecondaryTableBottom;
