import React from 'react';
import { useStyles, Checkbox as MaterialUiCheckbox } from './CheckboxStyles';
import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

interface Checkbox extends CheckboxProps {
  name: string;
  label: string;
  labelDescription?: string;
  checkboxClassName?: string;
  watch: any;
  control: any;
  trigger: any;
  error?: boolean;
}

const Checkbox = (props: Checkbox): JSX.Element => {
  const { 
    name, 
    label, 
    labelDescription, 
    disabled, 
    checkboxClassName, 
    control, 
    trigger, 
    error 
  } = props;
  
  const classes = useStyles();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, field): void => {
    field.onChange(e.target.checked);
    trigger(name);
  };

  return (
    <FormControlLabel
      className={classes.label}
      label={
        <>
          <span>{label}</span>
          <span>{labelDescription}</span>
        </>
      }
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <MaterialUiCheckbox
              className={clsx(classes.checkbox, checkboxClassName, error ? classes.error : '')}
              onChange={(e) => handleOnChange(e, field)}
              checked={field.value}
              disabled={disabled}
              inputRef={field.ref}
              ref={undefined}
            />
          )}
        />
      }
    />
  );
};

Checkbox.defaultProps = {
  label: '',
  color: 'primary',
  defaultChecked: false,
  inputProps: null,
  checked: false,
};

export default Checkbox;
