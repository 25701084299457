import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  Typography,
  Notice,
  BillingAddress,
  Divider,
  ButtonSecondary,
  AboutYourCover,
} from '../../components/atoms';
import { PaymentDetails } from '../../components/molecules';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './PaymentStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addYears, format, startOfMonth, subDays } from 'date-fns';
import creditCardType from 'credit-card-type';
import * as yup from 'yup';
import paymentSchema from './PaymentSchema.json';
import axios from 'axios';
import clsx from 'clsx';
import { convertDateToDlgFormat } from '../../utils/dateFormattingUtils';
import { getPersonalCoverPrice, getSecondVehiclePrice } from '../../utils/coverDetailsUtils';

const MASTERCARD = 'MASTERCARD';
const MAESTRO = 'MAESTRO';
const VISA = 'VISA';
const AMERICAN_EXPRESS = 'AMEX';

const libraryToLocalCardTypeMapping = {
  "visa": VISA,
  "mastercard": MASTERCARD,
  "maestro": MAESTRO,
  "american-express": AMERICAN_EXPRESS
};

export const Payment: React.FC = () => {
  const { activeStep, updateActiveStep, updateData, data, updateShowStepper, updateShowHero } = useContext(StepContext);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [cardinalInProgress, setCardinalInProgress] = useState(false);
  const minExpiryDate = startOfMonth(new Date());
  const classes = useStyles();
  const history = useHistory();

  const worldpayRef = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => worldpayRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    updateActiveStep(5);
    updateShowHero(false);
    updateShowStepper(true);
  }, []);

  const getCardType = (): string | null => {
    const cct = creditCardType(getValues('cardNumber').toString());
    const libraryCardType = cct[0]?.type === undefined || cct.length > 1 ? null : cct[0].type;
    const localCardType = libraryCardType !== null && libraryCardType !== undefined ? libraryToLocalCardTypeMapping[libraryCardType] : null;
    return localCardType !== undefined ? localCardType : null;
  };

  const schema = yup.object().shape({
    cardholdersName: yup.string().required('Please let us know the name on your card.'),
    cardNumber: yup
      .number()
      .typeError('Let us know your cards number.')
      .required('Let us know your cards number.')
      .when('cardType', {
        is: () => true,
        then: yup
          .number()
          .typeError('Card number is required.')
          .required('Card number is required.')
          .test('card-number', 'Invalid card number', (val: number | undefined, { createError }) => {
            const cardType = getCardType();
            if (cardType === MASTERCARD && val) {
              return new RegExp(
                '^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)[0-9]{12,15}$',
              ).test(val?.toString());

            } else if (cardType === VISA && val) {
              return new RegExp('^4[0-9]{12,18}?$').test(val.toString());

            } else if (cardType === AMERICAN_EXPRESS && val) {
              return new RegExp('^3[47][0-9]{13}$').test(val?.toString());

            } else if (cardType === MAESTRO) {
              return createError({
                message: MAESTRO + ' is not supported.',
              });

            } else {
              return false;
            }
          }),
      }),
    expiryDate: yup
      .date()
      .typeError('Invalid expiry date.')
      .required('Let us know your cards expiry date.')
      .min(minExpiryDate, "Date cannot be before today.")
      .nullable(),
    cardSecurityNumber: yup
      .string()
      .typeError('Invalid card security number.')
      .required('Card security number is required.')
      .test('card-security-number', 'Invalid card security number', (val, context) => {
        const cardType = context.parent.cardType;
        const regex = /^[0-9]+$/;
        const lengthMap = {
          MASTERCARD: 3,
          VISA: 3,
          AMEX: 4
        };

        if (!cardType) {
          // card type is missing, dont raise this error
          return true;
        }
        return !cardType || (val?.length === lengthMap[cardType] && regex.test(val || ''));
      }),
    cardType: yup.string().required(),

    postcodeLookup: yup.object().shape({
      firstLineOfAddress: yup.string().required('Please select an address.'),
      secondLineOfAddress: yup.string(),
      thirdLineOfAddress: yup.string(),
      town: yup.string().required('Please select an address.'),
      county: yup.string(),
      postcode: yup.string().required('Please enter a valid postcode.'),
    }),
  });



  const {
    formState: {
      errors,
    },
    control,
    watch,
    getValues,
    setValue,
    trigger,
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      cardSecurityNumber: '',
      worldpay3dSecureIframe: '',
      threeDSecure: false,
      paymentError: false,
      //IN accordance with legacy, we have line 1, line 2, line 3 (town) - use these, do not use the postcode lookup's third line of address
      // postcodeLookup: {
      //   firstLineOfAddress: data.addressLine1,
      //   secondLineOfAddress: data.addressLine2,
      //   thirdLineOfAddress: data.addressLine3,
      //   town: data.addressLine4,
      //   county: data.addressLine5,
      //   postcode: data.postcode,
      // },
      postcodeLookup: {
        firstLineOfAddress: data.billingAddressLine1 !== '' ? data.billingAddressLine1 : data.addressLine1,
        secondLineOfAddress: data.billingAddressLine2 !== '' ? data.billingAddressLine2 : data.addressLine2,
        thirdLineOfAddress: data.billingAddressLine3 !== '' ? data.billingAddressLine3 : data.addressLine3,
        town: data.billingAddressLine4 !== '' ? data.billingAddressLine4 : data.addressLine4,
        county: '',
        postcode: data.billingAddressPostCode !== '' ? data.billingAddressPostCode : data.postcode,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');
  const worldpay3dSecureIframe = watch('worldpay3dSecureIframe');
  const threeDSecure = watch('threeDSecure');


  // This will all need to be removed and moved to finalReview.tsx if the updated designs dont get approval
  // Goodluck whoever has to rewrite all of this.
  const paymentSuccessful = () => {
    updateData({
      ...data,
      ...getValues(),
      billingAddressLine1: plAddressLine1,
      billingAddressLine2: plAddressLine2,
      billingAddressLine3: plAddressLine3,
      billingAddressLine4: plAddressLine4,
      billingAddressLine5: plAddressLine5,
      billingAddressPostCode: plPostcode,
      postcode: plPostcode,
      paymentSuccessful: true,
    });
    setPaymentInProgress(false);
    updateShowStepper(false);
    history.push('/all-sorted');
  };

  useEffect(() => {
    // Update cardType on cardNumber change.
    const cardType = getCardType();
    if (cardType === null) return;
    if (getValues('cardType') !== cardType) {
      setValue('cardType', cardType);
    }
  }, [watch('cardNumber')]);

  useEffect(() => {
    const handleIframeCallback = (event) => {

      let data: null | {
        boolStatus: boolean;
        stringStatus: string;
        orderId: string;
      } = null;

      if (event != null && event.data != null) {
        try { data = JSON.parse(event.data); }
        catch { }
      } else {
        return;
      }
      if (!data?.stringStatus || !data?.orderId || data?.boolStatus == null || data?.boolStatus == undefined) {
      } else if (data?.boolStatus) {
        paymentSuccessful();
      } else {
        setValue('paymentError', true);
        setValue('worldpay3dSecureIframe', '');
        trigger('paymentError');
        setPaymentInProgress(false);
        updateData({
          ...data,
          ...getValues(),
          billingAddressLine1: plAddressLine1,
          billingAddressLine2: plAddressLine2,
          billingAddressLine3: plAddressLine3,
          billingAddressLine4: plAddressLine4,
          billingAddressLine5: plAddressLine5,
          billingAddressPostCode: plPostcode,
          postcode: plPostcode,
        });
      }
    };

    if (threeDSecure === false) return;
    window.addEventListener('message', handleIframeCallback, true);
    return () => {
      window.removeEventListener('message', handleIframeCallback, false);
    };
  }, [threeDSecure]);

  function isThirdPartyPayment() {
    const billingAddress = `${plAddressLine1 !== '' ? `${plAddressLine1}` : ''}` +
      `${plAddressLine2 !== '' ? (plAddressLine1 !== '' ? ` ${plAddressLine2}` : `${plAddressLine2}`) : ''}`;
    const billingPostcode = plPostcode;

    const policyAddress = `${data.addressLine1 !== '' ? `${data.addressLine1}` : ''}` +
      `${data.addressLine2 !== '' ? (data.addressLine1 !== '' ? ` ${data.addressLine2}` : `${data.addressLine2}`) : ''}`;
    const policyPostcode = data.postcode;

    if (billingAddress !== policyAddress && billingPostcode !== policyPostcode) {
      return "true";
    } else {
      return "false";
    }
  };

  const onSubmit = async () => {
    setPaymentInProgress(true);
    setValue('paymentError', false);
    trigger('paymentError');

    const generateJWT = () => {
      const jwtEndPoint =
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_JWT_ENDPOINT}?quoteId=` + data.quote;

      const Base64 = /[^A-Z0-9+\/=]/i;

      const getData = async (url) => {
        try {
          const resp = await axios.post(url);
          if (resp.data.token != null && Base64.test(resp.data.token)) {
            return resp.data.token;
          }
        } catch (err) {
          setValue('paymentError', true);
          trigger('paymentError');
          setPaymentInProgress(false);
        }
      };
      return getData(jwtEndPoint);
    };

    const formattedPolicyStartDate = data.policyStartDate
      ? convertDateToDlgFormat(data.policyStartDate.toString())
      : '';

    function baseOptionForBackend(baseOptionForSubmission: string): string {
      if (baseOptionForSubmission === "Premium UK PLUS") {
        return "PremiumUKPlus";
      } else if (baseOptionForSubmission === "Premium UK") {
        return "PremiumUK";
      } else if (baseOptionForSubmission === "Roadside & Recovery") {
        return "RoadsideRecovery";
      }
      return "";
    };

    const startDateForBackend = format(new Date(formattedPolicyStartDate), 'yyyy/MM/dd');
    const endDateForBackend = format(subDays(addYears(new Date(formattedPolicyStartDate), 1), 1), 'yyyy/MM/dd');

    generateJWT().then((jwtOutput) => {
      const innerHtml =
        "<body onload='document.collectionForm.submit();'>" +
        "<form id='collectionForm' name='collectionForm' method='POST' action=" + process.env.REACT_APP_DDC_ENDPOINT + ">" +
        "<input type='hidden' name='Bin' value='" + getValues('cardNumber').toString().substring(0, 6) + "'>" +
        "<input type='hidden' name='JWT' value='" +
        jwtOutput +
        "'>" +
        '</form>' +
        '</body>';

      //set iframe properties
      const iframe = document.createElement('iframe');
      iframe.id = 'ddcIframe';
      iframe.style.visibility = 'hidden';
      iframe.style.display = 'none';
      iframe.srcdoc = innerHtml;

      document.body.appendChild(iframe);

      const ddcTimeout = setTimeout(() => {
        setValue('paymentError', true);
        trigger('paymentError');
        setPaymentInProgress(false);
        setCardinalInProgress(false);
      }, 15000);

      const ddcEvent = async (event) => {
        if (cardinalInProgress === false) {
          setCardinalInProgress(true);
          if (event.origin === `${process.env.REACT_APP_DDC_ORIGIN}`) {
            window.removeEventListener('message', ddcEvent, false);
            const eventData = JSON.parse(event.data);
            console.warn('Merchant received a message:', eventData);
            if (eventData !== undefined && eventData.Status === true) {
              clearTimeout(ddcTimeout);

              const paymentObject = {
                Order: {
                  customer: {
                    first_name: data.firstName,
                    last_name: data.lastName,
                    address: {
                      house: data.addressLine1,
                      street: data.addressLine2,
                      town: data.addressLine4 ? data.addressLine4 : data.addressLine3,
                      postcode: data.postcode,
                    },
                    telephone: data.phoneNumber,
                    email: data.emailAddress
                  },
                  brand_no_contact: "false",
                  vehicle: {
                    registration: data.registrationNumber,
                    make: data.vehicleMake,
                    model: data.vehicleModel,
                    year_of_manufacture: new Date().getFullYear() - +data.vehicleAge
                  },
                  continuous: data.automaticRenewal,
                  third_party_payment: isThirdPartyPayment(),
                  payment: {
                    card_type: getValues('cardType'),
                    card_number: getValues('cardNumber'),
                    card_holder_name: getValues('cardholdersName'),
                    card_expiry_date: format(new Date(getValues('expiryDate') || ''), 'MM/yy'),
                    cvc: getValues('cardSecurityNumber'),
                    billing_address: `${plAddressLine1 !== '' ? `${plAddressLine1}` : ''}` +
                      `${plAddressLine2 !== '' ? (plAddressLine1 !== '' ? ` ${plAddressLine2}` : `${plAddressLine2}`) : ''}`,
                    ...((isThirdPartyPayment() === "true")
                      ? { billing_town: plAddressLine4 }
                      : {}
                    ),
                    billing_postcode: plPostcode,
                  },
                  start_date: startDateForBackend,
                  end_date: endDateForBackend,
                  quote_id: data.quote,
                  price_pence: data.coverPrice ? Math.round(data.coverPrice * 100) : 0,
                  vehicle_age_yrs: data.vehicleAge,
                  cover_options: {
                    base_option: baseOptionForBackend(data.baseOption),
                    additional_options: data.additionalOptions,
                  },
                  vehicleMultiIndicator: data.additionalOptions.includes('AdditionalVehicle') ? 'true' : 'false',
                  ...(data.additionalOptions.includes('AdditionalVehicle')
                    ? {
                      additional_vehicle: {
                        registration: data.additionalVehicleRegistrationNumber,
                        make: data.additionalVehicleMake,
                        model: data.additionalVehicleModel,
                        year_of_manufacture: new Date().getFullYear() - +data.additionalVehicleAge,
                      }
                    }
                    : {}
                  ),
                  ...(data.additionalOptions.includes('Personal')
                    ? {
                      partner: {
                        initial: data.partnerInitial,
                        surname: data.partnerSurname
                      },
                    }
                    : {}
                  ),
                  dfReferenceId: eventData.SessionId,
                }
              };

              try {
                const { data: paymentResponse } = await axios.post(
                  `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_ORDER_ENDPOINT}`,
                  paymentObject
                );

                if (paymentResponse.stringStatus === 'AUTHORISED') {
                  paymentSuccessful();
                } else if (paymentResponse.stringStatus === '3DSECURE') {
                  setCardinalInProgress(false);
                  setValue('worldpay3dSecureIframe', paymentResponse.iframe);
                  setValue('threeDSecure', true);
                  trigger('worldpay3dSecureIframe');
                  trigger('threeDSecure');
                  executeScroll();
                  // @ts-ignore
                  document.getElementById('challengeForm').submit();
                } else {
                  setValue('paymentError', true);
                  trigger('paymentError');
                  setPaymentInProgress(false);
                  setCardinalInProgress(false);
                }

              } catch (error) {
                setValue('paymentError', true);
                trigger('paymentError');
                setPaymentInProgress(false);
                setCardinalInProgress(false);
              }
            } else {
              setValue('paymentError', true);
              trigger('paymentError');
              setPaymentInProgress(false);
              setCardinalInProgress(false);
            }
          }
        }
      };
      window.addEventListener('message', ddcEvent, false);
    });
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
      ...getValues(),
      billingAddressLine1: plAddressLine1,
      billingAddressLine2: plAddressLine2,
      billingAddressLine3: plAddressLine3,
      billingAddressLine4: plAddressLine4,
      billingAddressLine5: plAddressLine5,
      billingAddressPostCode: plPostcode
    });
    history.push(steps[Step.LETS_REVIEW].url);
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Payment`} />
      <Grid container className={classes.stepper}>
        <Box>
          <Grid item xs={12} lg={12} className="pb1">
            <Typography variant="h2">
              Final details and payment.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.responsiveHeading}>
            <Typography variant="body1">
              Let us know your payment details and we&apos;ll take a one-off payment for your year of cover.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          {!paymentInProgress && (
            <>
              <Grid item xs={12} md={8}>
                <Typography variant="body2">
                  If your circumstances change between when you purchase your policy and the date it&apos;s meant to start, please call 0800 400 654 and let us know.
                </Typography>
              </Grid>

              <Typography variant="h3" className={classes.responsivePadding}>
                Card payment
              </Typography>

              {getValues('paymentError') && (
                <Notice
                  className={clsx(classes.maxWidth35, 'mb3')}
                  heading={paymentSchema.notice.heading}
                  message={paymentSchema.notice.message}
                  messageType="error"
                />
              )}

              <PaymentDetails
                cardholdersName={data.cardholdersName}
                cardNumber={data.cardNumber}
                expiryDate={data.expiryDate}
                cardSecurityNumber={''}
                getCardType={getCardType}
                control={control}
                errors={errors}
                AMERICAN_EXPRESS_TYPE_VALUE={AMERICAN_EXPRESS}
              />

              <Typography variant="h3" className="my3 pt3">
                Billing address
              </Typography>

              <Box className="mb2">
                <BillingAddress
                  addressLine1={plAddressLine1}
                  addressLine2={plAddressLine2}
                  addressLine3={plAddressLine3}
                  addressLine4={plAddressLine4}
                  addressLine5={plAddressLine5}
                  postcode={plPostcode}
                  plName="postcodeLookup.postcode"
                  plPostcode={plPostcode}
                  setValue={setValue}
                  trigger={trigger}
                  control={control}
                />
              </Box>

              {errors.postcodeLookup && (
                <Typography className="fieldError">
                  {errors.postcodeLookup?.firstLineOfAddress
                    ? errors.postcodeLookup?.firstLineOfAddress.message
                    : errors.postcodeLookup?.town
                      ? errors.postcodeLookup?.town.message
                      : errors.postcodeLookup?.postcode
                        ? errors.postcodeLookup.postcode.message
                        : ''}
                </Typography>
              )}

              <div className="pt3">
                <AboutYourCover
                  coverLevel={data.baseOption}
                  coverLevelPrice={data.priceData.find(item => item.option === data.baseOption)?.price}
                  hasSecondVehicle={data.additionalOptions.includes("AdditionalVehicle")}
                  secondVehiclePrice={getSecondVehiclePrice(data.options)}
                  hasPersonalCover={data.personalCover.includes('True') ? true : false}
                  personalCoverPrice={getPersonalCoverPrice(data.options)}
                  totalCostValue={data.coverPrice ? data.coverPrice : 0}
                >
                </AboutYourCover>
              </div>


              <Divider className="my3"></Divider>

              <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb2">
                  <Grid container className={clsx(classes.gridMainContainer, "mb2 mt1")}>
                    <Grid item xs={12} sm={6} className={classes.backButton}>
                      <Box>
                        <ButtonSecondary onClick={handleBack}>Back</ButtonSecondary>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.actionButton}>
                        <ButtonPrimary disabled={paymentInProgress} loading={paymentInProgress} type="submit">
                          Buy your cover
                        </ButtonPrimary>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </form>
            </>
          )}
          <div ref={worldpayRef}>
            {worldpay3dSecureIframe && <div dangerouslySetInnerHTML={{ __html: worldpay3dSecureIframe }} />}
          </div>
        </Grid>

      </Grid>
    </PageTemplate>
  );
};

export default Payment;
