import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    stepper: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .active': {
        color: theme.palette.common.white,
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    
    actionButton: {
      display: 'flex',
      justifyContent: 'end',
      paddingBottom: '5rem',
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
            minWidth: '18rem',
        },
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    maxWidth30: {
      maxWidth: '30rem',
    },
    maxWidth18: {
      maxWidth: '18rem !important',
    },
    responsiveHeading: {
      width: '100%',
      '@media (minWidth: 768px)': {
        width: '80%',
      },
    },
    bgLightBlue: {
      backgroundColor: theme.palette.primary.contrastText
    },
    bgBlue: {
      backgroundColor: theme.palette.primary.main,
    },
    divider: {
      height: '2px',
    }
  }),
  { index: 1 },
);
