import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { LinkSecondary, TextField, ButtonPrimary, Typography } from '../../../atoms';
import { useStyles } from './AdditionalVehicleLookUpStyles';
import axios from 'axios';

export interface IVLBackendItem {
  make: string;
  model: string;
  year: string;
}
interface IVehicleData {
  vlName: string;
  registrationNumber: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string | number;
  setValue: any;
  trigger: any;
  control: any;
  onClick?: (() => void) | undefined;
  vehicleOneRegistrationNumber: string;
}

export const AdditionalVehicleLookUp: React.FC<IVehicleData> = ({
  registrationNumber,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vlName,
  setValue,
  control,
  onClick,
  vehicleOneRegistrationNumber
}: IVehicleData): JSX.Element => {
  const [showLookup, setShowLookup] = useState(false);
  const [lookupComplete, setLookupComplete] = useState(false);
  const classes = useStyles();
  const [showErrorMessage, setShowErrorMessage] = useState({ visible: false, errorMessage: '' });

  const handleVLookupSubmit = async (onClick: (() => void) | undefined): Promise<void> => {
    if (onClick !== undefined) onClick(); // Handle onClick being passed down as a component prop
    setLookupComplete(false);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_VEHICLE_LOOKUP_URL}`,
        {
          vrm: registrationNumber.replace(/[^0-9a-z\s]/gi, '').replace(/ /g, '').trim()
        }
      );

      if (data.status == 200) {
        setValue('additionalVehicleLookUp.additionalVehicleMake', data.data.make);
        setValue('additionalVehicleLookUp.additionalVehicleModel', data.data.model);
        setValue('additionalVehicleLookUp.additionalVehicleYear', data.data.year);
        setValue('additionalVehicleLookUp.additionalVehicleRegistrationNumber', registrationNumber.replace(/[^0-9a-z\s]/gi, '').replace(/ /g, '').trim().toUpperCase());
        setLookupComplete(true);
      } else {
        setShowErrorMessage({
          visible: true,
          errorMessage: "Sorry, we couldn't find this vehicle’s details.",
        });
        setLookupComplete(false);
        setShowLookup(true);
      }
    } catch {
      setLookupComplete(false);
      //If the lookup does not return any vehicle, we need to clear the value of registration number so we handle the valiation to yup
      setValue('additionalVehicleLookUp.additionalVehicleRegistrationNumber', '');
      setShowErrorMessage({
        visible: true,
        errorMessage: "Sorry, we couldn't find this vehicle’s details.",
      });
    }
  };

  const handleShowVehicleLookup = () => {
    setValue('additionalVehicleLookUp.additionalVehicleRegistrationNumber', '');
    setValue('additionalVehicleLookUp.additionalVehicleMake', '');
    setValue('additionalVehicleLookUp.additionalVehicleModel', '');
    setValue('additionalVehicleLookUp.additionalVehicleYear', '');
    setShowLookup(true);
    setShowErrorMessage({ visible: false, errorMessage: "" });
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  useEffect(() => {
    if (registrationNumber === '') {
      setShowLookup(true);
    }
    else {
      setShowLookup(false);
    }
  }, [lookupComplete]);

  if (showLookup) {
    return (
      <>
        <Box className={classes.lookupContainer}>
          <TextField
            placeholder="YOUR REG"
            id="additionalVehicleRegistrationNumber"
            className={classes.lookupField}
            name={vlName}
            control={control}
            onKeyPress={handleOnKeyPress}
          />

          <ButtonPrimary
            className={classes.lookupBtn}
            name="additionalVehicleLookUp"
            disabled={registrationNumber === undefined ||
              registrationNumber === '' ||
              !registrationNumber.match(/^(?! )[A-Za-z0-9 ]+$/) ||
              registrationNumber.toUpperCase() === vehicleOneRegistrationNumber.toUpperCase()
            }
            onClick={() => handleVLookupSubmit(onClick)}
          >
            {'Find your vehicle' || 'Look Up'}
          </ButtonPrimary>
        </Box>

        {showErrorMessage.visible ?
          <div className="pt2">
            <Box className={classes.errorBox}>
              <Typography className={classes.maxWidth30}>{showErrorMessage.errorMessage}</Typography>
              <Typography variant="body2" className={classes.maxWidth30}>Please call us on <a className={classes.anchorText} href="tel:0800400654">0800 400 654.</a></Typography>
            </Box>
          </div>
          : <></>
        }
      </>

    );
  }

  return (
    //this is information display on a successful lookup
    <Box className={classes.vehicleLookUpContainer}>
      <Grid container className={classes.vehicleLookUpGrid}>
        <Grid className={classes.regBoxContainer} item xs={12} sm={3}>
          <div className={classes.regBox}>
            <Typography className="textCenter" variant="h3">{registrationNumber}</Typography>
          </div>
        </Grid>
        <Grid className={classes.responsivePadding} item xs={12} sm={9}>
          <Typography variant="body1" className="fw-500">
            {vehicleMake !== '' ? `${vehicleMake}, ` : ''} {vehicleModel !== '' ? `${vehicleModel} ` : ''}
          </Typography>
          <Typography className="pt1" variant="body2">
            {vehicleYear}
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.bottomHighlight}>
        <LinkSecondary onClick={handleShowVehicleLookup}>Not the right vehicle?</LinkSecondary>
      </Box>
    </Box>
  );
};

export default AdditionalVehicleLookUp;
