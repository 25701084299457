import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    stepper: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .active': {
        color: theme.palette.common.white,
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    actionButton: {
      display: 'flex',
      justifyContent: 'end',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
            minWidth: '14rem',
        },
      },
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        width: '100%',
        '& button': {
          margin: '1rem 0',
        },
      },
      '@media (min-width: 768px)': {
        padding: '1rem',
      },
    },
    backButton: {
      '@media (max-width: 767px)': {
        display: 'flex',
        alignItems: 'center',
      },
      '@media (min-width: 768px)': {
        padding: '1rem',
      },
    },
    responsiveHeading: {
      maxWidth: '80%',
      paddingBottom: '1rem',

      '@media (max-width: 768px)': {
        maxWidth: '100%',
      },
    },
    additionalExtrasDivider: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    personalCoverBody: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
      fontFamily: theme.fontFamilies.fsElliot,
    },
    personalCoverHeading: {
      marginTop: '0.1rem',
      marginBottom: '0.1rem'
    },
    personalCoverBtn: {
      width: '100%'
    },
    gridData: {
      justifyContent: 'center',
      alignItems: 'flex-end',
      paddingBottom: '1rem'
    },
    bgLightBlue: {
      backgroundColor: theme.palette.primary.contrastText
    },
    '&:active': {
      backgroundColor: theme.palette.green[50],
      border: `2px solid ${theme.palette.green[500]}`,
      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[900]
      },
    },
    personalCoverContainer: {
      marginTop: '0.5rem',
      marginLeft: '0.5rem'
    },
    summaryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center'
    },
    summaryDivider: {
      marginTop: '0.5rem',
      marginBottom: '0.6rem',
      height: '2px'
    },
    summaryLabel: {
      alignItems: 'flex-start',
      marginLeft: 0,
      marginTop: '0.5rem',
      marginBottom: '0.6rem',
      padding: '1.25rem',

      '& span': {
        ...theme.bodyStyles.body2,
        lineHeight: '1.75rem',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0.15rem 0 .5rem',
      },
    },
    removeLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bgBlue: {
      backgroundColor: theme.palette.primary.main,
    },
  }),
  { index: 1 },
);
