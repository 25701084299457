const ROADSIDE_AND_RECOVERY = 'Roadside & Recovery';
const PREMIUM_UK = 'Premium UK';
const PREMIUM_UK_PLUS = 'Premium UK PLUS';

export const populateComparisonTableWithPricing = (options) => {

  const optionMapping = {
    PremiumUK: "Premium UK",
    PremiumUKPlus: "Premium UK PLUS",
    RoadsideRecovery: "Roadside & Recovery",
  };

  const priceDataWithPrices = options.map((option) => {
    const optionId = option.option_id;
    const price = (option.base_price_pence / 100).toFixed(2);
    const name = optionMapping[optionId];

    return {
      option: name,
      price: `${price}`,
    };
  });
  return priceDataWithPrices;
};

export const getPersonalCoverPrice = (options) => {
  if (options) {
    const personal = options.find(item => item.additional_options.Personal);
    return (personal.additional_options.Personal.price_pence / 100).toFixed(2);
  }
};

export const getSecondVehiclePrice = (options) => {
  if (options) {
    const additionalVehicle = options.find(item => item.additional_options.Personal);
    return (additionalVehicle.additional_options.AdditionalVehicle.price_pence / 100).toFixed(2);
  }
};

export function totalCostForAboutYourCover(priceDataForTable, hasAdditionalVehicle, personalCoverLocal, baseOptionForSubmission, options) {
  const priceOfCoverSelection = Number(priceDataForTable.find(item => item.option === baseOptionForSubmission)?.price);
  const priceforSecondVehicle = hasAdditionalVehicle ? Number(getSecondVehiclePrice(options)) : 0;
  const priceforPersonalCover = personalCoverLocal.includes("True") ? Number(getPersonalCoverPrice(options)) : 0;
  return priceforSecondVehicle + priceforPersonalCover + (priceOfCoverSelection ? priceOfCoverSelection : 0);
};

export const determineAvailableOptions = (
  breakdownCover: string,
  additionalVehicle: string,
  motorHomeCampervan: boolean,
  carCaravan: boolean,
  carMotorbike: boolean,
  coverOptionCarAndCaravan: boolean
): string[] => {
  const hasAdditionalVehicle: boolean = additionalVehicle?.toLowerCase() === 'true' || additionalVehicle?.toLowerCase() === 'yes' || additionalVehicle?.toLowerCase() === 'dual';
  const hasBreakdownCover: boolean = breakdownCover?.toLowerCase() === 'true' || breakdownCover?.toLowerCase() === 'yes';

  let canSelectPremiumUk = false;
  let canSelectPremiumUkPlus = false;
  let canSelectRoadsideRecovery = false;

  //coverOptionCarAndCaravan is true if the user has selected the car and caravan option (coveroption1Selected in the legacy)
  //if that is false, then the customer wants cover just for the car.
  //homecover(breakdowncover) triggers premiumuk and premiumukplus. if homecover is false, then cover is roadside and recovery.
  const availableOptions: string[] = [];

  //two vehicles
  if (hasAdditionalVehicle) {

    if (hasBreakdownCover) {
      if (motorHomeCampervan && !carCaravan && !carMotorbike) {
        //2 motorhomes
        //premiumuk and premiumukplus
        canSelectPremiumUk = true;
        canSelectPremiumUkPlus = true;
        availableOptions.push(PREMIUM_UK, PREMIUM_UK_PLUS);
      }
      else if (!motorHomeCampervan && carCaravan && !carMotorbike) {
        //2 cars with caravans
        // (coverOption1 selected - legacy)
        // I think it is: cover for the cars and caravans
        if (coverOptionCarAndCaravan) {
          // if the customer wants cover for the cars and the caravans
          // premium uk plus
          canSelectPremiumUkPlus = true;
          availableOptions.push(PREMIUM_UK_PLUS);
        } else {
          // if the customer wants cover for just the cars
          // premium uk
          canSelectPremiumUk = true;
          availableOptions.push(PREMIUM_UK);
        }
      }
      else if (!motorHomeCampervan && !carCaravan && carMotorbike) {
        //2 cars
        //premiumuk
        canSelectPremiumUk = true;
        availableOptions.push(PREMIUM_UK);
      }
      else if (motorHomeCampervan && carCaravan && !carMotorbike) {
        // a motorhome and a car with a caravan
        // premium uk and premium uk plus
        canSelectPremiumUk = true;
        canSelectPremiumUkPlus = true;
        availableOptions.push(PREMIUM_UK, PREMIUM_UK_PLUS);
      }
      else if (motorHomeCampervan && !carCaravan && carMotorbike) {
        // a motorhome and a car
        // premium uk and premium uk plus
        canSelectPremiumUk = true;
        canSelectPremiumUkPlus = true;
        availableOptions.push(PREMIUM_UK, PREMIUM_UK_PLUS);
        // additional vehicle removed in this scenario
        //resultsModel.car = false;
      }
      else if (!motorHomeCampervan && carCaravan && carMotorbike) {
        // a car with a caravan and an additional car
        if (coverOptionCarAndCaravan == true) {
          // if the customer wants cover for the cars and the caravans
          // premium uk plus
          canSelectPremiumUkPlus = true;
          availableOptions.push(PREMIUM_UK_PLUS);
          // additional vehicle removed in this scenario
          //resultsModel.car = false;
        }
        else {
          // if the customer wants cover for just the cars
          // premium uk
          canSelectPremiumUk = true;
          availableOptions.push(PREMIUM_UK);
        }
      }

    } else {
      canSelectRoadsideRecovery = true;
      availableOptions.push(ROADSIDE_AND_RECOVERY);
    }
  } else {

    //one vehicle
    if (hasBreakdownCover) {

      if (motorHomeCampervan && !carCaravan && !carMotorbike) {
        canSelectPremiumUk = true;
        canSelectPremiumUkPlus = true;
        availableOptions.push(PREMIUM_UK, PREMIUM_UK_PLUS);
        // availableOptions.push(PREMIUM_UK_PLUS);
      } else if (!motorHomeCampervan && carCaravan && !carMotorbike) {
        if (coverOptionCarAndCaravan) {
          canSelectPremiumUkPlus = true;
          availableOptions.push(PREMIUM_UK_PLUS);
        } else {
          canSelectPremiumUk = true;
          availableOptions.push(PREMIUM_UK);
        }
      } else if (!motorHomeCampervan && !carCaravan && carMotorbike) {
        canSelectPremiumUk = true;
        availableOptions.push(PREMIUM_UK);
      }
    } else {
      canSelectRoadsideRecovery = true;
      availableOptions.push(ROADSIDE_AND_RECOVERY);
    }
  }

  return availableOptions;
};
