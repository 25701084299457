import React from 'react';
import { useStyles, datePickerTheme } from './DatePickerStyles';
import { isFuture, isValid, format as DateFnsFormat } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-GB';
import { KeyboardDatePicker, MuiPickersUtilsProvider, DatePickerView } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { InputAdornmentProps } from '@material-ui/core';

interface IDatePicker {
  style?: React.CSSProperties;
  id: string;
  name: string;
  label?: string;
  control: any;
  placeholder?: string;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement | undefined>;
  autoOk?: boolean;
  format?: string;
  views?: Array<'year' | 'date' | 'month'>;
  disableFuture?: boolean;
  className?: string;
  disablePast?: boolean;
  minDate?: Date | string;
  maxDate?: Date | string;
  defaultValue?: Date | string | null;
  initialFocusedDate?: Date | string;
  openTo?: DatePickerView | undefined;
  inputAdornmentProps?: Partial<InputAdornmentProps>;
}

class DatePickerUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return DateFnsFormat(date, 'E, do MMMM');
  }
}

export const DatePicker: React.FC<IDatePicker> = ({
  style,
  id,
  name,
  label,
  control,
  placeholder,
  onKeyPress,
  autoOk,
  format,
  views,
  disableFuture,
  className,
  disablePast,
  minDate,
  maxDate,
  initialFocusedDate,
  openTo,
  inputAdornmentProps
}: IDatePicker): JSX.Element => {
  const classes = useStyles();

  //TODO: policyStartDate? possibly add policyEnd
  const handleOnChange = (date: Date | number | null, name: string, onChange: any): void => {
    if (name === 'policyStartDate' && date !== null) {
      if (isFuture(date)) {
        onChange(date);
      } else if (isValid(date)) {
        onChange();
      } else {
        onChange(date);
      }
    } else {
      onChange(date);
    }
  };

  return (
    <ThemeProvider theme={datePickerTheme}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ...rest } }) => (
          <MuiPickersUtilsProvider utils={DatePickerUtils} locale={locale}>
            <KeyboardDatePicker
              {...rest}
              ref={null}
              views={views}
              disableFuture={disableFuture}
              disablePast={disablePast}
              minDate={minDate}
              maxDate={maxDate}
              style={style}
              className={clsx(classes.datePicker, value !== null ? classes.value : '', className)}
              id={id}
              placeholder={placeholder}
              label={label}
              value={value}
              onChange={(date) => handleOnChange(date, name, onChange)}
              onKeyPress={onKeyPress}
              variant="inline"
              format={format}
              inputVariant="outlined"
              autoOk={autoOk}
              initialFocusedDate={initialFocusedDate}
              openTo={openTo}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputAdornmentProps={inputAdornmentProps}
              inputProps={{
                autoComplete: 'disabled'
              }}
              helperText={null}
            />
          </MuiPickersUtilsProvider>
        )}
      />
    </ThemeProvider>
  );
};

DatePicker.defaultProps = {
  placeholder: 'DD/MM/YYYY',
  autoOk: true,
  format: 'dd/MM/yyyy',
};

export default DatePicker;
