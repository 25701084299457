import React from 'react';
import { useStyles } from './AccordionStyles';
import { CSSAccordion, AccordionSummary, AccordionDetails } from './AccordionStyles';
import { Typography } from '../../atoms';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IAccordion  {
  heading: string;
  body: JSX.Element;
  defaultExpanded?: boolean;
}

const Accordion: React.FC<IAccordion> = ({ heading, body, defaultExpanded }: IAccordion): JSX.Element => {
  const classes = useStyles();
  return (
    <div>
      <CSSAccordion style={{ backgroundColor: 'rgba(44, 148, 167, 0.1'}} defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />} className={classes.accordionSummary} aria-controls="accordion1d-content" id="accordion1d-header">
          <Typography variant="h3" className={classes.accordionHeading}>{heading}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          <div className="pt1">
            {body}
          </div>
        </AccordionDetails>
      </CSSAccordion>
    </div>
  );
};

export default Accordion;
