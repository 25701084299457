import { makeStyles, Theme } from '@material-ui/core/';

export const useStyles = makeStyles((theme: Theme) => ({
  stateTrackerContainer: {
    position: 'absolute',
    top: 0,
    width: '24rem',
  },
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: '1rem 1rem',
    maxHeight: '28rem',
    overflowY: 'auto',
  },
  sticky: {
    position: 'sticky',
    display: 'block',
    top: '1rem',
  },
  pre: {
    fontSize: '0.8rem',
    whiteSpace: 'pre',
    overflow: 'auto',
  },
  icon: {
    cursor: 'pointer',
    position: 'fixed',
    top: '0.75rem',
    right: '1%',
    zIndex: 999,
  },
}));
