export const CoverDetailsSchema = {
    NumberOfVehicles: [
        {
            label: 'One vehicle',
            value: 'Single',
        },
        {
            label: 'Two vehicles',
            value: 'Dual',
        },
    ],
    HomeCall: [
        {
            label: 'Yes',
            value: 'True',
        },
        {
            label: 'No',
            value: 'False',
        },
    ],
    CoverAtHomeResultsSingle: [
        {
            label: 'Your car and caravan',
            value: 'First',
        },
        {
            label: 'Only your car',
            value: 'Second',
        },
    ],
    CoverAtHomeResultsDual: [
        {
            label: 'Your motorhome / campervan, and your car / motorbike',
            value: 'First',
        },
        {
            label: 'Your motorhome / campervan, and your car and caravan',
            value: 'Second',
        },
    ],
    PersonalCover: [
        {
            label: 'Yes',
            value: 'True',
        },
        {
            label: 'No',
            value: 'False',
        },
    ],
};

export default CoverDetailsSchema;