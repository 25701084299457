import React, { useState, useEffect } from 'react';
import theme from '../../../theme';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useStyles } from './ComparisonTableStyles';
import CheckIcon from "@material-ui/icons/Check";
import comparisonTableSchema from './ComparisonTableSchema.json';
import { ButtonSecondaryTableBottom } from '../ButtonSecondaryTableBottom';

type PriceData = {
  option: string;
  price: string;
};
interface ComparisonTableProps {
  options: string[];
  isCaravan?: boolean;
  priceData: PriceData[];
  onSelect: (option: string) => void;
}

const getDocument = (filePath: string) => window.open(filePath);

export const ComparisonTable = ({ options, isCaravan, priceData, onSelect }: ComparisonTableProps) => {
  const [selectedOption, setSelectedOption] = useState<string>(options.length === 1 ? options[0] : '');
  const classes = useStyles();

  const data: {
    title: string;
    subtitle: string;
    caravan?: boolean;
    features: {
      values: string[];
    }[];
  }[] = comparisonTableSchema;

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
  };

  useEffect(() => {
    if (options.length === 1) {
      setSelectedOption(options[0]);
      onSelect(options[0]);
    }
  }, [options, onSelect]);
  const filterData = (tableData: typeof data, filterOptions: string[]) => {
    if (isCaravan) {
      return tableData.filter((item) => {
        if (item.caravan && filterOptions.includes("Premium UK PLUS")) {
          return true;
        }
        return filterOptions.some((option) =>
          item.features.some((feature) => feature.values.includes(option))
        );
      });
    } else {
      return tableData.filter((item) =>
        filterOptions.some((option) =>
          item.features.some((feature) => feature.values.includes(option)) && !item.caravan
        )
      );
    }
  };

  
  const pricesForOptions = {
    "Roadside & Recovery": priceData.find(item => item.option === "Roadside & Recovery")?.price,
    "Premium UK": priceData.find(item => item.option === "Premium UK")?.price,
    "Premium UK PLUS": priceData.find(item => item.option === "Premium UK PLUS")?.price,
  };
  
  return (
    <TableContainer aria-label="comparison table of the cover options">
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell></TableCell>
            {options.map((option) => (
              <TableCell
                key={option}
                align="center"
                onClick={() => handleOptionClick(option)}
                className={classes.tableHeaderItems}
                role="button"
                aria-pressed={option === selectedOption}
                aria-label={option}
                scope="col"
                style={{
                  backgroundColor:
                    option === selectedOption ? theme.palette.primary.dark : theme.palette.common.white,
                  color:
                    option === selectedOption ? theme.palette.common.white : theme.palette.common.black,
                  cursor: "pointer",
                  borderBottom: "none",
                }}
              >
                <Typography variant="h5">{option}</Typography>
                <Typography variant="h6">£{pricesForOptions[option]}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {filterData(data, options).map((row) => (
            <TableRow key={row.title}>
              <TableCell>
                <Typography variant="h4">{row.title}</Typography>
                <Typography variant="body1" color="textSecondary" className="pt1">{row.subtitle}</Typography>
              </TableCell>
              {options.map((option, index) => (
                <TableCell
                  key={index}
                  align="center"
                  role="button"
                  aria-pressed={option === selectedOption}
                  aria-label={option}
                  scope="col"
                  onClick={() => handleOptionClick(options[index])}
                  className={`${options[index] === selectedOption ? classes.tabLines : ''}`}
                >
                  <Typography variant="subtitle1">
                    {row.features.some((feature) => feature.values.includes(option)) ? (<CheckIcon className={classes.checkIcon} />) : ("")}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}

          <Typography variant="body2" className="py05">
            <a onClick={() => getDocument('/pdfs/MAYDAY-Policy-booklet.pdf')} target="_blank" rel="noreferrer" className="anchorLink">
              Policy booklet
            </a>
          </Typography>

          {options.map((option) => (
            <TableCell
              key={option}
              align="center"
              onClick={() => handleOptionClick(option)}
              style={{
                color:
                  option === selectedOption ? theme.palette.common.white : theme.palette.common.black,
                cursor: "pointer",
                borderBottom: "none",
              }}
              className={classes.specificButton}
              role="cell"
              aria-label={option}
            >
              <ButtonSecondaryTableBottom
                key={`button-${option}`}
                onClick={() => handleOptionClick("")}
              >
                {option === selectedOption ? "Selected" : "Select"}
              </ButtonSecondaryTableBottom>
            </TableCell>
          ))}


        </TableBody>
      </Table>
    </TableContainer>
  );
};


export default ComparisonTable;
