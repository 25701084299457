import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        customerInfoContent: {
            display: 'flex',
            alignContent: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            alignItems: 'flex-start',
            '@media (max-width: 767px)': {
                flexDirection: 'column'
            }
        },
        displayAddressValue: {
            width: '20rem'
        },
        summaryDivider: {
            marginTop: '0.5rem',
            marginBottom: '0.6rem',
            height: '2px'
        },
        bgBlue: {
            backgroundColor: theme.palette.primary.main,
        }
    }),
    { index: 1 },
);
