import React, { useEffect, useState } from 'react';
import { Grid, FormControl, FormGroup, FormControlLabel, Radio, RadioProps } from '@material-ui/core';
import { Controller } from 'react-hook-form';

interface ISquareRadioGroup extends RadioProps {
  vehicleCount: number;
  name: string;
  onSelectionChange: (any) => void;
  selectedValues: { [key: string]: boolean };
  control: any;
  rules?: any;
  onChange?: any;
  onClick?: (e) => void;
  watch: any;
}

const SquareRadioGroup: React.FC<ISquareRadioGroup> = ({
  vehicleCount,
  name,
  onSelectionChange,
  selectedValues,
  control,
  rules,
  onChange,
  onClick,
}: ISquareRadioGroup): JSX.Element => {
  const [state, setState] = useState({
    motorHomeCampervan: false,
    carCaravan: false,
    carMotorbike: false,
  });

  const [selectCount, setSelectCount] = useState(0);

  useEffect(() => {
    if (selectedValues) {
      setState({
        motorHomeCampervan: selectedValues.motorHomeCampervan || false,
        carCaravan: selectedValues.carCaravan || false,
        carMotorbike: selectedValues.carMotorbike || false,
      });
      setSelectCount(Object.values(selectedValues).reduce((acc: number, cur: boolean) => (cur ? acc + 1 : acc), 0));
    }
  }, [selectedValues]);

  const handleChange = (event, type) => {
    let count = selectCount;
    
    switch (type) {
      case 'motorHomeCampervan':
        count = motorHomeCampervan ? --count : ++count;
        setState({ ...state, [type]: !motorHomeCampervan });
        onSelectionChange({ ...state, [type]: !motorHomeCampervan });
        break;
      case 'carCaravan':
        count = carCaravan ? --count : ++count;
        setState({ ...state, [type]: !carCaravan });
        onSelectionChange({ ...state, [type]: !carCaravan });
        break;
      case 'carMotorbike':
        count = carMotorbike ? --count : ++count;
        setState({ ...state, [type]: !carMotorbike });
        onSelectionChange({ ...state, [type]: !carMotorbike });
        break;
    }

    setSelectCount(count);
  };

  const { motorHomeCampervan, carCaravan, carMotorbike } = state;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Grid>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Radio
                    onChange={onChange}
                    inputRef={field.ref}
                    ref={undefined}
                    checked={motorHomeCampervan}
                    onClick={(e) => handleChange(e, 'motorHomeCampervan')}
                    name="motorHomeCampervan"
                  />
                }
                onClick={onClick}
                disabled={selectCount >= vehicleCount && !motorHomeCampervan ? true : false}
                label="Motorhome / campervan"
              />
              <FormControlLabel
                control={
                  <Radio checked={carCaravan} onClick={(e) => handleChange(e, 'carCaravan')} name="carCaravan" />
                }
                onClick={onClick}
                disabled={selectCount >= vehicleCount && !carCaravan ? true : false}
                label="Car with a caravan"
              />
              <FormControlLabel
                control={
                  <Radio checked={carMotorbike} onClick={(e) => handleChange(e, 'carMotorbike')} name="carMotorbike" />
                }
                onClick={onClick}
                disabled={selectCount >= vehicleCount && !carMotorbike ? true : false}
                label="Car / motorbike"
              />
            </FormGroup>
          </FormControl>
        </Grid>
      )}
    />
  );
};

export default SquareRadioGroup;
