import { makeStyles, Theme, withStyles, Checkbox as MaterialUiCheckbox } from '@material-ui/core';

export const Checkbox = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(MaterialUiCheckbox);

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    backgroundColor: 'rgba(44, 148, 167, 0.1)',
    alignItems: 'flex-start',
    margin: '.5rem 0rem .6rem 0rem',
    padding: '1.25rem',

    '& span': {
      ...theme.bodyStyles.body2,
      lineHeight: '1.75rem',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0.15rem 0 .5rem',
    },

    '& span:first-child': {
      ...theme.leads.lead1,
      lineHeight: '1.6rem',
      padding: '0 0.15rem',
    },

    '@media (max-width: 600px)': {
      '& span:first-child': {
        ...theme.leads.lead2,
        lineHeight: '1.8rem',
      },
      '& span': {
        ...theme.bodyStyles.body2,
      },
    },
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.primary.dark,
    marginLeft: 0,
  },
  error: {
    color: theme.palette.utility.redDark,
  },
}), { index: 1 });
