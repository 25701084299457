import React from 'react';
import theme from './theme';
import './fonts.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StepProvider, steps, Step } from './contexts/StepContext';
import { CoverDetails, YourCover, YourDetails, LetsReview, Payment, AllSorted, StyleGuide, ContactUs, Timeout } from './pages';
import GlobalCss from './globalCss';
import { StylesProvider } from '@material-ui/core/styles';
// import { ErrorBoundary } from './components/atoms/ErrorBoundary';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <GlobalCss />
          {/* <ErrorBoundary> potential before go live revert, need to discuss */}
          <StepProvider>
            <Switch>
              <Route exact path="/" component={CoverDetails} />
              <Route exact path="/coverOptions/:id" component={CoverDetails} />
              <Route exact path={steps[Step.YOUR_COVER].url} component={YourCover} />
              <Route exact path={steps[Step.YOUR_DETAILS].url} component={YourDetails} />
              <Route exact path={steps[Step.LETS_REVIEW].url} component={LetsReview} />
              <Route exact path={steps[Step.PAYMENT].url} component={Payment} />
              <Route exact path="/all-sorted" component={AllSorted} />
              <Route exact path="/ContactUs" component={ContactUs}/>
              <Route exact path="/timeout" component={Timeout}/>
              {process.env.REACT_APP_INCLUDE_TEST_PAGES === 'true' && <Route exact path="/guide" component={StyleGuide} />}
              {/* <Route path="*" component={() => {
                window.location.href = 'https://www.greenflag.com';
                return null;
                // any undefined path will redirect to greenflag 
              }} /> */}
            </Switch>
          </StepProvider>
          {/* </ErrorBoundary> */}
        </StylesProvider>
      </ThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
