import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    stepper: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .active': {
        color: theme.palette.common.white,
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    allSortedDisplay: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    bgBlue: {
      backgroundColor: theme.palette.primary.main,
    },
    divider: {
      height: '2px',
    },
    allSortedBody: {
      marginTop: '1rem',
      marginBottom: '0.5rem'
    },
    allSortedHeader: {
      padding: '2rem',
      '@media (max-width: 768px)': {
        padding: '0.5rem',
      },
    }
  }),
  { index: 1 },
);
