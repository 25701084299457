import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '100%',
    paddingBottom: '2rem',
  },
  preFooter: {
    backgroundColor: theme.palette.primary.main,
    opacity: '.1',
    margin: '0',
    paddingTop: '1rem',
  },
  textHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  footerCardIcons: {
    maxHeight: '2.5rem',
    margin: '1rem 1rem 0 0',
  },
  anchorLinks: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  container: {
    maxWidth: '1280px',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
  },
  gridContainer: {
    marginTop: '1rem',
  },
}));
