import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    nextStepBtnContainer: {
      margin: '1rem 1rem 0.5rem 1rem',
      '& button': {
        marginRight: '1.5rem',
      },
    },
    btnContainer: {
      margin: '1rem',
    },
    error: {
      color: theme.palette.error.main,
    },
    inputLabel: {
      fontFamily: 'fs-elliot-pro-bold',
      marginBottom: '0.5rem',
    },
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '10rem',
      marginTop: '0.5rem',
      marginRight: '1rem',
    },
    checkboxContainer: {
      marginTop: '1rem',
    },
    formContainer: {
      marginTop: '1.5rem',
    },
    yourPriceContainer: {
      marginTop: '1rem',
    },
    datePickerContainer: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    datePicker: {
      marginTop: '0.5rem',
    },
    genericContainer: {
      marginTop: '1rem',
    },
    postcodeLookupContainer: {
      margin: '1rem 0',
    },
    postcodeLookupContainerField: {
      '@media (max-width: 767px)': {
        maxWidth: '100%',
        flexDirection: 'column',
      },
      marginBottom: '1rem',
      flexDirection: 'row',
    },
    actionBtnsContainer: {
      marginTop: '0.75rem',
    },
    submitBtn: {
      marginRight: '1rem',
    },
    sticky: {
      position: 'sticky',
      display: 'block',
      top: '9rem',
    },
    dNoneMobile: {
      '@media (max-width: 425px)': {
        display: 'none',
      },
    },
    dOnlyMobile: {
      '@media (min-width: 426px)': {
        display: 'none',
      },
    },
    recaptcha: {
      margin: '1rem 0 3rem 0',
    },
    contactSideMobile: {
      padding: '0 4rem 0 2rem',
      '@media (max-width: 1279px)': {
        padding: '1rem 0rem 0rem 0rem',
      },
    },
    linkContainer: {
      margin: '1.5rem 0',
      '& a': {
        marginRight: '1rem',
      },
    },
    bgBlue: {
      backgroundColor: theme.palette.primary.main,
    },
    headerStyling: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      '@media (max-width: 767px)': {
        padding: '1.5rem 1.5rem 1.5rem 1.5rem',
      },
      padding: '1.5rem 1.5rem 1.5rem 8rem',
    },
    stepper: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .active': {
        color: theme.palette.common.white,
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    summaryLabel: {
      alignItems: 'flex-start',
      marginLeft: 0,
      marginTop: '0.5rem',
      marginBottom: '0.6rem',
      padding: '1.25rem',

      '& span': {
        ...theme.bodyStyles.body2,
        lineHeight: '1.75rem',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0.15rem 0 .5rem',
      },
    },
    bgLightBlue: {
      backgroundColor: theme.palette.primary.contrastText
    },
    summaryContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center'
    },
    summaryDivider: {
      marginTop: '0.5rem',
      marginBottom: '0.6rem',
      height: '2px'
    },
    additionalExtrasDivider: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    personalCoverBody: {
      marginTop: '1rem',
      marginBottom: '0.5rem',
      fontFamily: theme.fontFamilies.fsElliot,
    },
    personalCoverHeading: {
      marginTop: '0.1rem',
      marginBottom: '0.1rem'
    },
    gridData: {
      justifyContent: 'center',
      alignItems: 'flex-end',
      paddingBottom: '1rem'
    },
    removeLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    personalCoverBtn: {
      width: '100%'
    },
    personalCoverContainer: {
      marginTop: '0.5rem',
      marginLeft: '0.5rem'
    },
  }),
  { index: 1 },
);
