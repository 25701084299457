import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    rootBtnClass: {
      ...theme.typography.button,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      fontSize: '1rem',
      fontWeight: 500,
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: '4px',
      textTransform: 'none',
      minHeight: '2.5rem',
      minWidth: '8rem',
      '@media (min-width: 425px)': {
        maxWidth: '7rem',
      },
      '@media (min-width: 767px)': {
        maxWidth: '7rem',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[500],
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        '& .MuiSvgIcon-root': {
          color: theme.palette.dark[900],
        },
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        border: `3px solid rgb(148, 193, 201)`,
      },
      '&:active': {
        backgroundColor: theme.palette.green[50],
        border: `2px solid ${theme.palette.green[500]}`,
        '& .MuiSvgIcon-root': {
          color: theme.palette.dark[900]
        },
      },
    },
    loadingButton: {
      backgroundColor: theme.palette.utility.greenHover,
      border: `2px solid ${theme.palette.green[500]}`,
    },
    loadingAnimation: {
      position: 'relative',
      width: '10px',
      height: '10px',
      borderRadius: '5px',
      backgroundColor: theme.palette.green[900],
      color: theme.palette.green[900],
      animation: '$dotFlashing 1s infinite linear alternate',
      animationDelay: '.5s',

      '&:before, &:after': {
        content: "''",
        display: 'inline-block',
        position: 'absolute',
        top: 0,
      },

      '&:before': {
        left: '-15px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: theme.palette.green[900],
        color: theme.palette.green[900],
        animation: '$dotFlashing 1s infinite alternate',
        animationDelay: '0s',
      },
      '&:after': {
        left: '15px',
        width: '10px',
        height: '10px',
        borderRadius: '5px',
        backgroundColor: theme.palette.green[900],
        color: theme.palette.green[900],
        animation: '$dotFlashing 1s infinite alternate',
        animationDelay: '1s',
      },
    },
    '@keyframes dotFlashing': {
      '0%': {
        backgroundColor: theme.palette.green[800],
      },
      '50%, 100%': {
        backgroundColor: theme.palette.green[400],
      },
    },
  }),
  { index: 1 },
);
