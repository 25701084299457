import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    stepper: {
      display: 'flex',
      maxWidth: '1280px',
      margin: '0 auto',
      padding: '1.5rem 1.5rem 1.5rem 2rem',
      marginBottom: '2rem',
      '@media (max-width: 768px)': {
        padding: '1.5rem',
      },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .active': {
        color: theme.palette.common.white,
      },
      '@media (max-width: 440px)': {
        padding: '1rem',
      },
    },
    gridMainContainer: {
      maxWidth: '1280px',
      margin: 'auto',
      padding: '1rem 1rem 2.5rem 1rem',
    },
    responsiveHeading: {
      maxWidth: '90%',
      paddingBottom: '1rem',

      '@media (max-width: 768px)': {
        maxWidth: '100%',
      },
    },
    datePicker: {
      marginTop: '0.5rem',
    },
    error: {
      color: theme.palette.error.main,
    },
    bgLightBlue: {
      backgroundColor: theme.palette.primary.contrastText
    },
    yourCoverLabel: {
      alignItems: 'flex-start',
      marginLeft: 0,
      marginTop: '0.5rem',
      marginBottom: '0.6rem',
      padding: '1.25rem',

      '& span': {
          ...theme.bodyStyles.body2,
          lineHeight: '1.75rem',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 0.15rem 0 .5rem',
      },
    },
    divider: {
      height: '2px',
      backgroundColor: theme.palette.primary.main,
      marginTop: '2rem',
    },

    minWidth20: {
      minWidth: '20rem',
    },
    actionButton: {
      display: 'flex',
      justifyContent: 'end',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
            minWidth: '14rem',
        },
      },
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        width: '100%',
        '& button': {
          margin: '1rem 0',
        },
      },
      '@media (min-width: 768px)': {
        padding: '1rem',
      },
    },
    backButton: {
      '@media (max-width: 767px)': {
        display: 'flex',
        alignItems: 'center',
      },
      '@media (min-width: 768px)': {
        padding: '1rem',
      },
    },
    
    registrationNumberLabel: {
      ...theme.form.labels.inputField.label,
      color: theme.palette.dark[800],
      marginBottom: '1rem'
    },
    
    minWidth17: {
      minWidth: '17rem',
    },
    lookupContainer: {
      display: 'flex',
      '@media (max-width: 500px)': {
        flexDirection: 'column'
      }
    },
    lookupButtonHeight: {
      minHeight: '3.5rem'
    },
  }),
  { index: 1 },
);
