import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { STRIPE_BASE } from '../../molecules/Stripe/CardElementStyles';
import regPlate from '../../../assets/img/logo/gb-plate.jpg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        fontFamily: 'fs-elliot-pro-regular',
        borderRadius: '2px',
        backgroundColor: theme.palette.primary.contrastText,
        '& svg': {
          color: theme.palette.dark[500],
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.secondary.dark}`,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.black,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.dark[800],
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.main,
      },
      '& div.Mui-error': {
        borderColor: theme.palette.utility.redLight,
        boxShadow: `inset 0 0 0 4px ${theme.palette.utility.redDark}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderColor: `${theme.palette.utility.redLight}`,
        },
      },
    },
    textFieldEnabled: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.dark[300],
      },
    },
    stripe: {
      ['& .' + STRIPE_BASE]: {
        height: '1em',
      },
    },
    vehicleLookup: {
      backgroundImage: `url(${regPlate})`,
      backgroundPosition: '0px 50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '32px',
      paddingLeft: '50px',
    },
  }),
);
