import React from 'react';
import { useStyles } from './NavBarStyles';
import { AppBar, Grid, Container, Box } from '@material-ui/core';
import { NavStepper, StateEditor, StateTracker, Typography } from '../../atoms';
import { StepContext } from '../../../contexts/StepContext';
import greenFlagLogo from '../../../assets/img/logo/colour_-_on_light.svg';
import caravanClubLogo from '../../../assets/img/logo/caravan-club-logo.png';
import clsx from 'clsx';

export const NavBar: React.FC = (): JSX.Element => {
  const context = React.useContext(StepContext);
  const { showStepper, showHero } = context;
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Container className={classes.container}>
          <Grid container>
            <Grid item className={classes.caravanLogoGridContainer} xs={6} lg={6}>
              <img src={caravanClubLogo} alt="Caravan Club Logo" />
            </Grid>
            <Grid item className={classes.greenFlagLogoGridContainer} xs={6} lg={6}>
              <img src={greenFlagLogo} alt="Green Flag Logo" />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Container className={classes.containerPadding}>
        <Grid container className={classes.containerPadding}>
          <Grid item xs={12}>
            {!showHero ? <></> : 
              <Box className={classes.heroBackground}>
                {/* Hero box */}
                <Typography className={classes.heroHeadings} variant="h1">
                  MAYDAY
                </Typography>
                <Typography className={clsx(classes.heroHeadings, "pt1")} variant="h2">
                  UK Breakdown Cover
                </Typography>
                <Typography className={clsx(classes.heroHeadings, "py1")} variant="h2">
                  Tailor-made for our members.
                </Typography>
                <Box 
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="5rem"
                  className={clsx(classes.heroContentBox, "pl3")}
                >
                  <Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="body2">
                        Breakdown en route to a campsite?
                      </Typography>
                      <p>
                        Dual recovery will get you there and back.
                      </p>
                      <Typography variant="body2">
                        Mud, flood, or snow?
                      </Typography>
                      <p>
                        We don&apos;t charge extra for specialist recoveries.
                      </p>
                      <Typography variant="body2">
                        No vehicle age, weight or size restrictions.
                      </Typography>
                      <p>
                        If it follows UK legal towing requirements, we&apos;ll cover it.
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            }
            {!showStepper ? <></> : <NavStepper />}
            {process.env.NODE_ENV === 'development' ? (
              <>
                <StateTracker />
                <StateEditor />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default NavBar;
