import { makeStyles } from '@material-ui/core/styles';
import heroBackground from '../../../assets/img/heroBackground/mayday-hero-imagery.png';

export const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      backgroundColor: theme.palette.common.white,
      boxShadow: 'none',
    },
    heroHeadings: {
      color: theme.palette.primary.main,
      '@media (min-width: 767px)': {
        paddingLeft: '3rem',
      },   
    },
    heroBackground: {
      padding: '1.5rem',
      '@media (max-width: 767px)': {
        backgroundColor: 'rgba(44, 148, 167, 0.1)',
        minHeight: '10rem',
      },
      '@media (min-width: 768px)': {
        backgroundImage: `url(${heroBackground})`,
        backgroundRepeat: 'round',
        minHeight: '29rem',
      },
    },
    containerPadding: {
      padding: '0rem 0rem 0rem 0rem',
    },
    heroContentBox: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '5px',
      display: 'flex',
      top: '50%',
      justifyContent: 'flex-start',
      alignContent: 'center',
      flexDirection: 'unset',
      padding: '1.5rem',
      '@media (min-width: 426px)': {
        maxWidth: '35rem',
        marginLeft: '3rem',
      },
      '@media (min-width: 767px)': {
        paddingLeft: '3rem',
      },
    },
    container: {
      maxWidth: '1280px',
      padding: '1.5rem 1.2rem',
    },
    greenFlagLogoGridContainer: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'end',
      '& img': {
        maxWidth: '250px',
        maxHeight: '50px',
        height: 'auto',
        '@media (max-width: 768px)': {
          width: '150px',
        },
        '@media (max-width: 320px)': {
          width: '110px',
        },
      },
    },
    caravanLogoGridContainer: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'start',
      '& img': {
        maxWidth: '250px',
        maxHeight: '50px',
        height: 'auto',
        '@media (max-width: 768px)': {
          width: '150px',
        },
        '@media (max-width: 320px)': {
          width: '110px',
        },
      },
    },
    contactGridContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'baseline',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1.5rem',
      },
    },
    helpButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dNoneMobile: {
      '@media (max-width: 1279px)': {
        display: 'none',
      },
    },
    dNoneDesktop: {
      '@media (min-width: 1280px)': {
        display: 'none',
        margin: '1rem 0',
      },
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    dOnlyMobile: {
      '@media (min-width: 426px)': {
        display: 'none',
      },
    },
  }),
  { index: 1 },
);
