import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        ...theme.layouts.stepGrid,
        stepper: {
            display: 'flex',
            maxWidth: '1280px',
            margin: '0 auto',
            padding: '1.5rem 1.5rem 1.5rem 2rem',
            marginBottom: '2rem',
            '@media (max-width: 768px)': {
                padding: '1.5rem',
            },
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '& .active': {
                color: theme.palette.common.white,
            },
            '@media (max-width: 440px)': {
                padding: '1rem',
            },
        },
        responsiveHeading: {
            maxWidth: '90%',
            paddingBottom: '1rem',
            
            '@media (max-width: 768px)': {
                maxWidth: '100%',
            },
        },
        responsiveXPadding: {
            '@media (max-width: 1440px)': {
                paddingLeft: '1rem',
                paddingRight: '1rem'
            },
            
            paddingLeft: '3rem',
            paddingRight: '3rem',

            '@media (min-width: 1440px)': {
                paddingLeft: '6rem',
                paddingRight: '4rem'
            },
        },
        bgBlue: {
            backgroundColor: theme.palette.primary.main,
        },
        divider: {
            height: '1px'
        },
        actionButton: {
            display: 'flex',
            justifyContent: 'end',
            '& button': {
                minWidth: '13rem',
                '@media (min-width: 321px)': {
                    minWidth: '14rem',
                },
            },
            '@media (max-width: 767px)': {
                flexDirection: 'column',
                width: '100%',
                '& button': {
                    margin: '1rem 0',
                },
            },
            '@media (min-width: 768px)': {
                padding: '1rem',
            },
        },
        backButton: {
            '@media (max-width: 767px)': {
                display: 'flex',
                alignItems: 'center',
            },
            '@media (min-width: 768px)': {
                padding: '1rem',
            },
        },
        customerInfoContent: {
            display: 'flex',
            alignContent: 'center',
            marginTop: '1rem',
            marginBottom: '1rem',
            alignItems: 'flex-start',
            '@media (max-width: 767px)': {
                flexDirection: 'column'
            }
        },
        displayAddressValue: {
            width: '20rem'
        },
    }),
    { index: 1 },
);
