import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  active: {
    color: theme.palette.green.main
  },
  icon: {
    cursor: 'pointer',
    position: 'fixed',
    top: '2.8rem',
    right: '1%',
    zIndex: 999
  },
  selectDropDown: {
    marginBottom: '1rem'
  },
  stateEditorContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    minWidth: '25rem',
    '@media (max-width: 500px)': {
      minWidth: 'initial'
    }
  },
  tooltipContainer: {
    margin: '0 0.5rem'
  },
  inputLabel: {
    marginBottom: '0.5rem'
  },
  saveBtn: {
    marginTop: '1rem'
  },
}));

export const jsonInputStyle = {
  body: {
    maxHeight: '25rem',
    overflow: 'auto',
  },
  container: {
    height: 'auto',
    width: 'auto',
    overflow: 'auto',
  },
  outerBox: {
    height: 'auto',
    width: 'auto',
  },
};