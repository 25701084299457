import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        yourCoverLabel: {
            alignItems: 'flex-start',
            marginLeft: 0,
            marginTop: '0.5rem',
            marginBottom: '0.6rem',
            padding: '1.25rem',

            '& span': {
                ...theme.bodyStyles.body2,
                lineHeight: '1.75rem',
                display: 'flex',
                flexDirection: 'column',
                padding: '0 0.15rem 0 .5rem',
            },
        },
        bgLightBlue: {
            backgroundColor: theme.palette.primary.contrastText
        },
        divider: {
            height: '1px'
        },
        bgBlue: {
            backgroundColor: theme.palette.primary.main,
        },
        coverContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            padding: '0.8rem',
            width: '784',
            '@media (max-width: 767px)': {
                padding: '0.5rem',
            }
        },
        endDivider: {
            height: '2px'
        }
    }),
    { index: 1 },
);
