import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableTouchRipple: true,
      focusVisibleClassName: 'focused-element',
    },
  },
  overrides: {
    MuiButton: {
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: '1.4rem',
        },
      },
    },
    MuiRadio: {
      root: {
        color: '#2c94a7',
      },
      colorSecondary: {
        '&$checked': {
          color: '#2c94a7',
        },
      },
    }
  },
  typography: {
    h1: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '2rem', // 32px
        lineHeight: '2rem', // 32px
      },
      fontSize: '2.75rem', // 44px
      lineHeight: '2.75rem', // 44px
    },
    h2: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '1.5625rem', // 25px
        lineHeight: '1.5625rem', // 25px
      },
      fontSize: '2.0625rem', // 33px
      lineHeight: '2.0625rem', // 33px
    },
    h3: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '1.375rem', // 22px
        lineHeight: '1.5rem', // 24px
      },
      fontSize: '1.5rem', // 24px
      lineHeight: '1.625rem', // 26px
    },
    h4: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '1.25rem', // 20px
        lineHeight: '1.375rem', // 22px
      },
      fontSize: '1.375rem', // 22px
      lineHeight: '1.5rem', // 24px
    },
    h5: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '1rem', // 16px
        lineHeight: '1.125rem', // 18px
      },
      fontSize: '1.125rem', // 18px
      lineHeight: '1.25rem', // 20px
    },
    button: {
      fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
  fontFamilies: {
    fsElliotProBold: {
      fontFamily: ['fs-elliot-pro-bold'].join(','),
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    fsElliot: {
      fontFamily: ['fs-elliot-pro-light'].join(','),
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    fsElliotRegular: {
      fontFamily: ['fs-elliot-pro-regular'].join(','),
    },
    oswald: {
      fontFamily: ['Oswald-Regular', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    roboto: {
      fontFamily: ['fs-elliot-pro-regular', 'fs-elliot-pro-light', 'fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
  leads: {
    lead1: {
      fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.75rem',
    },
    lead2: {
      fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.375rem',
    },
  },
  overlines: {
    overline1: {
      fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.188rem',
      textTransform: 'uppercase',
    },
    overline2: {
      fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.063rem',
      textTransform: 'uppercase',
    },
  },
  form: {
    headings: {
      small: {
        fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: '1.5rem',
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '1.75rem',
      },
      large: {
        fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: '2rem',
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '2.188rem',
      },
    },
    labels: {
      inputField: {
        label: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.125rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.375rem',
          color: '#1A1A1A',
        },
        hint: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        moreInfo: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.25rem',
          textDecoration: 'underline',
        },
      },
      selectFieldSmall: {
        label: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.25rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        hint: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        moreInfo: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.25rem',
          textDecoration: 'underline',
        },
      },
      selectFieldLarge: {
        label: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.5rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
        hint: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.125rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
        moreInfo: {
          fontFamily: ['fs-elliot-pro-bold', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.5rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    // For these next 4 rows please refer to the following url for reference: https://app.abstract.com/projects/6c890e1b-c723-4368-aa65-a8f42ccfe9d1/branches/main/commits/c49719b619fd558c6735fc30511e6dacfc3576b8/files/46de2d44-92be-4b91-af96-c50a737ef19b/layers/DE783E7C-06A7-4B26-8973-41A6A2702519?mode=build&selected=root-FE5BD941-E28C-4793-91D1-561A6B646FB9
    primary: {
      // First row
      dark: '#006070', // darkest shade on top row on style guide in the top section
      main: '#2c94a7', // second shade on top row on style guide in the top section
      light: 'rgba(44, 148, 167, 0.9)', // third shade on top row on style guide in the top section
      contrastText: 'rgba(44, 148, 167, 0.1)',
    },
    secondary: {
      // second row of colours on styleguide
      dark: '#343a40', // first option
      main: '#6c757d', // second
      light: '#c0cdcd', // third
    },
    green: {
      // Third row
      main: '#02703e',
      light: '#3f966e',
    },
    misc: {
      // Row 4
      error: '#dc3545', // red shade on style guide in the mixture section
      warning: '#ffc107', // orange shade on style guide in the mixture section
      light: '#17a2b8', // lighter blue shade on style guide in the mixture section
      green: '#28a745', // (number 3 on row) on style guide in the mixture section
      grey: '#6c757d', // on style guide in the mixture section
      main: '#007bff', // (number 6 on row) brighter blue shade on style guide in the mixture section
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    background: {
      default: '#FFFFFF',
    },
    error: {
      main: '#FC005E',
      light: '',
    },
    dark: {
      main: '#292929',
      light: '',
      [50]: '#B1B0Ab',
      [100]: '#9E9D99',
      [200]: '#8A8986',
      [300]: '#767673',
      [400]: '#626260',
      [500]: '#4F4F4E',
      [600]: '#3B3B3B',
      [700]: '#292929',
      [800]: '#1A1A1A',
      [900]: '#0A0A0A',
    },
    light: {
      main: '#C5C4BE',
      light: '',
      [50]: '#FCFBF6',
      [100]: '#F7F5F0',
      [200]: '#F3F1EC',
      [300]: '#ECEBE6',
      [400]: '#E5E4DF',
      [500]: '#DFDED9',
      [600]: '#D9D8D2',
      [700]: '#D2D1CB',
      [800]: '#CCCBC5',
      [900]: '#C5C4BE',
    },
    utility: {
      amberLight: '#E4B462',
      amberDark: '#CD963A',
      redLight: '#E46262',
      redDark: '#CD3A3A',
      purpleLight: '#6A62E4',
      purpleDark: '#6A3ACD',
      greenHover: '#D3F6CA', // Button components
      btnDisabledLightBg: 'rgba(108, 117, 125, 0.2)',
      btnDisabledDarkBg: 'rgba(108, 117, 125, 0.2)',
      linkPrimaryUnderlineEnabled: '#95C99E',
      linkFocusBg: '#CCF2C6',
      linkDisabledText: '#A2A19E',
      linkDisabledUnderline: '#C9C8C5',
      linkSecondaryUnderlineEnabled: '#535352',
      noticeError: '#FBEFEB',
      modalFooter: '#EBE9E5',
    },
  },
  shadows: [
    'none', // 0
    '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2)', // 1
    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2)', // 2
    '0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.2)', // 3
    '0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)', // 4
    '0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)', // 5
    '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)', // 6
    '0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 5px 6px -3px rgba(0, 0, 0, 0.2)', // 7
    '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)', // 8
    '0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(0, 0, 0, 0.2)', // 9
    '0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)', // 10
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  layouts: {
    stepGrid: {
      gridMainContainer: {
        maxWidth: '1280px',
        margin: 'auto',
        padding: '1rem',
      },
      gridMain: {
        padding: '1rem',
      },
    },
  },
  bodyStyles: {
    body1: {
      fontFamily: 'fs-elliot-pro-regular !important',
      '@media (max-width: 767px)': {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.125rem', // 18px
      },
      fontSize: '1.125rem', // 18px
      lineHeight: '1.375rem', // 22px
      letterSpacing: '0px',
      
    },
    body2: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.125rem', // 18px
      },
      fontSize: '1rem', // 16px
      lineHeight: '1.375rem', // 22px
      fontWeight: 'bold',
      letterSpacing: '0px',
    },
    body3: {
      fontFamily: 'fs-elliot-pro-bold !important',
      '@media (max-width: 767px)': {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.125rem', // 18px
      },
      fontSize: '1.125rem', // 18px
      lineHeight: '1.375rem', // 22px
      letterSpacing: '0px',
      
    }
  },
});

export default theme;
