import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
  },
  navbar: {
    position: 'static',
    display: 'block',
    '@media (max-width: 767px)': {
      marginBottom: '0rem',
    },
    top: 0,
    zIndex: 1,
  },
}));
