import React, { useContext } from 'react';
import { Grid, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './FooterStyles';
import { Typography } from '../../atoms';
import { StepContext, steps, Step } from '../../../contexts/StepContext';
import clsx from 'clsx';

import visaFooterLogo from '../../../assets/img/cards/footer-visa-secure.png';
import mastercardFooterLogo from '../../../assets/img/cards/footer-mastercard-id-check.png';

declare const OneTrust: any;

export const Footer: React.FC = (): JSX.Element => {
  const { updateActiveStep } = useContext(StepContext);
  const history = useHistory();

  const classes = useStyles();

  const ContactUsLink = async () => {
    updateActiveStep(5);
    history.push(steps[Step.CONTACT].url);
  };

  return (
    <>
      <div className={classes.preFooter}>
      </div>
      <footer className={classes.root}>
        <Container className={classes.container}>
          <Grid className={classes.gridContainer} container direction="row">
            <Grid item xs={12} md={10}>
              <Typography>
                MAYDAY vehicle rescue is provided by Green Flag and is underwritten by U K Insurance Limited. 
                Registered office: The Wharf, Neville Street, Leeds LS1 4AZ. Registered in England No.1179980. 
                Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. 
                <> </><a className={classes.anchorLinks} href="https://www.fca.org.uk" rel="noreferrer" target="_blank">www.fca.org.uk</a> Register number 202810.
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              {/* empty grid */}
            </Grid>
            <Grid className="pt2">
              <Grid item xs={12} md={10}>
                <Typography>
                  You will need to have Adobe Reader version 5 or above installed on your computer to read the PDF&apos;s on the page. 
                  If it&apos;s not already installed, or you need to view the PDF&apos;s in an accessible format, you can download Adobe Reader (opens in a new window) or view PDF&apos;s as accessible html (opens in a new window)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <img className={classes.footerCardIcons} src={mastercardFooterLogo} alt="MasterCard Logo" />
              <img className={classes.footerCardIcons} src={visaFooterLogo} alt="Visa Secure Logo" />
            </Grid>
          </Grid>
          <Grid className="pt2">
            <Grid container>
              <Grid item xs={12}>
                <a 
                  className={classes.anchorLinks}
                  href="/pdfs/SiteTAndC_26.pdf"
                  target="_blank" 
                  rel="noreferrer"
                >
                  Site Terms & Conditions
                </a>
              </Grid>
              <Grid className="pt1" item xs={12}>
                <a 
                  className={classes.anchorLinks} 
                  onClick={ContactUsLink}
                  rel="noreferrer"
                  tabIndex={0}
                > 
                  Contact Us
                </a>
              </Grid>
              <Grid className="pt1" item xs={12}>
                <a 
                  className={classes.anchorLinks}
                  href="/pdfs/Copyright_26.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Copyright
                </a>
              </Grid>
              <Grid className="pt1" item xs={12}>
                <a 
                  className={classes.anchorLinks} 
                  href="https://u-k-insurance.co.uk/mayday.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy
                </a>
              </Grid>
              <Grid className="pt1" item xs={12}>
                <a 
                  className={classes.anchorLinks} 
                  href="https://www.greenflag.com/cookies-notice?src=mayday"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookies Notice
                </a>
              </Grid>
              <Grid className="pt1" item xs={12}>
                <a 
                  href="#top"
                  onClick={() => OneTrust.ToggleInfoDisplay()} 
                  id="ot-sdk-btn" 
                  target="_self" 
                  rel="noreferrer" 
                  className={clsx(classes.textHover, classes.anchorLinks)}
                >
                  Manage Cookies
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </>
  );
};

export default Footer;