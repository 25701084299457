import React, { useContext, useEffect } from 'react';
import {
    StepChecker,
    DocumentTitle,
    ButtonSecondary,
    Typography,
} from '../../components/atoms';
import { Grid, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ContactUsStyles';
import { StepContext } from '../../contexts/StepContext';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

export const ContactUs: React.FC = () => {
    const { data, updateShowHero, updateShowStepper } = useContext(StepContext);
    const classes = useStyles();


    useEffect(() => {
        if (window['lpTag']) window['lpTag'].section = ['Direct Line', 'Mayday', 'Contact Us Page'];

        updateShowHero(true);
        updateShowStepper(false);
    }, []);
    
    const {
        } = useForm({
            mode: 'onSubmit',
            reValidateMode: 'onChange',
            defaultValues: {
                ...data,
                showLookup: true,
            },
            shouldFocusError: true,
            shouldUnregister: true,
    });

    return (
        <PageTemplate>
            <StepChecker />
            <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Contact Us`} />
            
            <Grid container className={classes.gridMainContainer}>
                <Grid item xs={12} lg={12} className={classes.gridMain}>
                    <Typography variant="h1">
                        Contact Us
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} className={classes.gridMain}>
                    <Typography variant="h3">
                        Upgrade Your Cover.
                    </Typography>
                    <Typography variant="body1" className="pt1">
                        If you wish to upgrade your cover, simply call us for free on 0800 400 654. Please note, calls may be recorded.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={12} className={classes.gridMain}>
                    <Typography variant="h3">
                        Administration and customer enquiries
                    </Typography>
                    <Typography variant="body1" className="pt1">
                        If you have any queries about your policy, or if any of your personal details have changed, please call us on 0800 400 654.
                    </Typography>
                </Grid>
            </Grid>

            <Grid container className={clsx(classes.gridMainContainer, "mb2")}>
                <Grid item xs={12} sm={6} className={classes.backButton}>
                    <Box>
                        <ButtonSecondary 
                            href="javascript: history.go(-1)"
                            aria-label="Back button"
                        >
                            Back
                        </ButtonSecondary>
                    </Box>
                </Grid>
            </Grid>
        </PageTemplate>
    );
};

export default ContactUs;
