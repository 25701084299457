import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...theme.typography.button,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.common.white,
      textTransform: 'none',
      minHeight: '2.5rem',
      '& svg': {
        marginRight: '0.25rem',
      },
    },
    underline: {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textUnderlinePosition: 'under',
      textDecorationColor: theme.palette.common.white,

      '&:hover': {
        cursor: 'pointer',
        textDecorationThickness: '2px',
        textDecorationColor: theme.palette.common.black,
      },
      '&:focus': {
        textDecorationThickness: '2px',
        backgroundColor: theme.palette.primary.dark,
        border: '3px solid #94c1c9',
        borderRadius: '4px',
      },
    },
    disabled: {
      ...theme.typography.button,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: 'rgba(108, 117, 125, 0.7)',
      borderBottom: `2px solid rgba(108, 117, 125, 0.7)`,
      paddingBottom: '2px',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
  { index: 1 },
);
