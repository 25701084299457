import React from 'react';
import { useStyles } from './RadioButtonGroupStyles';
import { RadioGroup, FormControlLabel, RadioProps } from '@material-ui/core';
import { Radio } from './RadioButtonGroupStyles';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';

interface IData {
  label: string;
  value: string | number;
  description?: string;
}
interface IRadioButtonGroup extends RadioProps {
  ariaLabel?: string;
  style?: React.CSSProperties;
  data: Array<IData>;
  name: string;
  control: any;
  rules?: any;
  inputLabelStyle?: any;
  className?: string;
  onChange?: any;
  defaultValue?: string;
  onClick?: (e) => void;
  watch: any;
}

export const RadioButtonGroup: React.FC<IRadioButtonGroup> = ({
  ariaLabel,
  style,
  data,
  name,
  control,
  rules,
  className,
  onChange,
  onClick,
  defaultValue,
  watch,
}: IRadioButtonGroup) => {
  const classes = useStyles();
  const selectedValue = watch(name);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <RadioGroup className={className} aria-label={ariaLabel} {...field}>
          {data.map((entry: IData, index: number) => {
            return (
              <FormControlLabel
                key={index}
                value={entry.value}
                style={style}
                className={clsx(
                  classes.root,
                  selectedValue === entry.value,
                )}
                control={<Radio onChange={onChange} inputRef={field.ref} ref={undefined} />}
                label={
                  <>
                    <span className={classes.selectedRadioBtnMainText}>{entry.label}</span>
                    {entry?.description ? (
                      <span className={classes.selectedRadioBtnSecondaryText}>{entry.description}</span>
                    ) : (
                      <></>
                    )}
                  </>
                }
                onClick={onClick}
              />
            );
          })}
        </RadioGroup>
      )}
    />
  );
};

export default RadioButtonGroup;
