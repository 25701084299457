import React from 'react';
import { useStyles } from './yourCoverFinalReviewStyles';
import { Typography } from '..';
import { Box, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';

interface IYourCoverFinalReview {
    coverStartDate: Date | string | null;
    coverEndDate: Date | string | null;
}

const YourCoverFinalReview: React.FC<IYourCoverFinalReview> = ({
    coverStartDate,
    coverEndDate,
}: IYourCoverFinalReview): JSX.Element => {
    const classes = useStyles();

    const policyStartDate = coverStartDate ? format(new Date(coverStartDate), 'dd/MM/yyyy') : '';
    const policyEndDate = coverEndDate ? format(new Date(coverEndDate), 'dd/MM/yyyy') : '';

    return (
        <>
            <div className="pl1">
                <Grid container className={classes.gridMainContainer}>
                    <Grid xs={12} className="pl1">
                        <Box className="py05">
                            <Typography variant="h3">
                                Your cover
                            </Typography>
                        </Box>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Cover start date</Typography>
                            <Typography variant="body3">{policyStartDate}</Typography>
                        </Box>
                        <Box className={clsx(classes.customerInfoContent, "py05")}>
                            <Typography variant="body1" className={classes.displayAddressValue}>Cover end date</Typography>
                            <Typography variant="body3">{policyEndDate}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default YourCoverFinalReview;